import { lazy } from 'react';
const Scene = lazy(() => import('./scene'));

const sceneRoutes = [
  {
    path: '/scene',
    component: Scene
  }
];

export default sceneRoutes;
