import { lazy } from "react";

const Dryeroutput = lazy(() => import("./Dryeroutput"));

const dryeroutputRoutes = [
  {
    path: "/dryeroutput",
    component: Dryeroutput,
  },
];

export default dryeroutputRoutes;
