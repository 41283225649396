import { lazy } from "react";

const Prtopo = lazy(() => import("./Prtopo"));

const prtopoRoutes = [
  {
    path: "/prtopo",
    component: Prtopo,
  },
];

export default prtopoRoutes;
