import { lazy } from "react";

const Adpmpayprocess = lazy(() => import("./Adpmpayprocess"));

const adpmpayprocessRoutes = [
  {
    path: "/adpmpayprocess",
    component: Adpmpayprocess,
  },
];

export default adpmpayprocessRoutes;
