import { lazy } from "react";

const Mins = lazy(() => import("./Mins"));

const minsRoutes = [
  {
    path: "/mins",
    component: Mins,
  },
];

export default minsRoutes;
