import { lazy } from "react";

const Dryeroutputreport = lazy(() => import("./Dryeroutputreport"));

const dryeroutputreportRoutes = [
  {
    path: "/dryeroutputreport",
    component: Dryeroutputreport,
  },
];

export default dryeroutputreportRoutes;
