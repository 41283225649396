import { lazy } from 'react';
const orderUpdate = lazy(() => import('./orderUpdate'));

const orderUpdateRoutes = [
  {
    path: '/orderUpdate',
    component: orderUpdate
  }
];

export default orderUpdateRoutes;
