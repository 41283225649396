import { lazy } from "react";

const Gtno = lazy(() => import("./Gtno"));

const gtnoRoutes = [
  {
    path: "/gtno",
    component: Gtno,
  },
];

export default gtnoRoutes;
