import { lazy } from "react";

const Latexdispatch = lazy(() => import("./Latexdispatch"));

const latexdispatchRoutes = [
  {
    path: "/latexdispatch",
    component: Latexdispatch,
  },
];

export default latexdispatchRoutes;
