import { lazy } from "react";

const Horizontalcutter = lazy(() => import("./Horizontalcutter"));

const horizontalcutterRoutes = [
  {
    path: "/horizontalcutter",
    component: Horizontalcutter,
  },
];

export default horizontalcutterRoutes;
