import { lazy } from 'react';

const latexpurchasing = lazy(() => import('./latex-purchasing'));

const latexpurchasingRoutes = [
  {
    path: '/sop/latex-purchasing',
    component: latexpurchasing
  }
];

export default latexpurchasingRoutes;
