import { lazy } from "react";

const Salesreturn = lazy(() => import("./Salesreturn"));

const localinvoiceRoutes = [
  {
    path: "/salesreturn",
    component: Salesreturn,
  },
];

export default localinvoiceRoutes;
