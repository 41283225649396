import jwtAuthService from '../../services/jwtAuthService';
import { setUserData } from './UserActions';
import history from '@history.js';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export function loginWithEmailAndPassword({ username, password }) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_LOADING,
    });

    jwtAuthService
      .loginWithEmailAndPassword(username, password)
      .then((user) => {
        dispatch(setUserData(user));

        if (user.uInfo.changePwd === 'YES') {
          history.push({
            pathname: '/select-company',
          });
          localStorage.setItem('login', 'true');
        } else {
          history.push({
            pathname: '/change-password',
          });
        }
        return dispatch({
          type: LOGIN_SUCCESS,
        });
      })
      .catch((error) => {
        history.push({
          pathname: '/session/signin/1',
        });

        return dispatch({
          type: LOGIN_ERROR,
          payload: error,
        });
      });
  };
}

export function resetPassword({ email }) {
  return (dispatch) => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD,
    });
  };
}
