import { lazy } from "react";

const Apdrup = lazy(() => import("./Apdrup"));

const apdrupRoutes = [
  {
    path: "/apdrup",
    component: Apdrup,
  },
];

export default apdrupRoutes;
