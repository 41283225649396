import { lazy } from "react";

const Pettycashrenew = lazy(() => import("./Pettycashrenew"));

const pettycashrenewRoutes = [
  {
    path: "/pettycashrenew",
    component: Pettycashrenew,
  },
];

export default pettycashrenewRoutes;
