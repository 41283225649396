import { lazy } from 'react';
const showRoom = lazy(() => import('./showroom.jsx'));

const  showRoomRoutes= [
  {
    path: '/sop/showroom',
    component: showRoom
  }
];

export default showRoomRoutes;
