import { lazy } from "react";

const ProdPlanningTwo = lazy(() => import("./ProdPlanningTwo"));

const prodPlanningTwoRoutes = [
  {
    path: "/prodplanningtwo",
    component: ProdPlanningTwo,
  },
];

export default prodPlanningTwoRoutes;
