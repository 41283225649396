import { lazy } from "react";

const ChangePassword = lazy(() => import("./ChangePassword"));


const changePasswordRoutes = [
  {
    path: "/change-password",
    component: ChangePassword
  },
  
  
];

export default changePasswordRoutes;