import { lazy } from "react";

const Stocktransfer = lazy(() => import("./Stocktransfer"));

const stocktransferRoutes = [
  {
    path: "/stocktransfer",
    component: Stocktransfer,
  },
];

export default stocktransferRoutes;
