import { lazy } from "react";

const Prapprovernew = lazy(() => import("./Prapprovernew"));

const prApproverNew = [
  {
    path: "/prapprover",
    component: Prapprovernew,
  },
];

export default prApproverNew;
