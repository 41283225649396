import { lazy } from 'react';
const Adpmbulkproc = lazy(() => import('./adpmbulkproc'));

const adpmbulkprocRoutes = [
  {
    path: '/bulkproc',
    component: Adpmbulkproc
  }
];

export default adpmbulkprocRoutes;