import { lazy } from "react";

const Setoff = lazy(() => import("./Setoff"));

const setoffRoutes = [
  {
    path: "/setoff",
    component: Setoff,
  },
];

export default setoffRoutes;
