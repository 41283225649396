import { lazy } from "react";

const Processpaymnetpettycash = lazy(() => import("./Processpaymnetpettycash"));

const processpaymnetpettycashRoutes = [
  {
    path: "/processpaymnetpettycash/:batchId",
    component: Processpaymnetpettycash,
  },
];

export default processpaymnetpettycashRoutes;
