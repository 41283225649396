import { lazy } from 'react';
const addotapp = lazy(() => import('./addotapp'));

const addotappRoutes = [
  {
    path: '/addotapp',
    component: addotapp
  }
];

export default addotappRoutes;
