import { lazy } from "react";

const Prapprover = lazy(() => import("./Prapprover"));

const prApprover = [
  {
    path: "/prapprover1",
    component: Prapprover,
  },
];

export default prApprover;
