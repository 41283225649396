import { lazy } from "react";

const Liberalitycreationlatex = lazy(() => import("./Liberalitycreationlatex"));

const liberalitycreationlatexRoutes = [
  {
    path: "/liberalitycreation",
    component: Liberalitycreationlatex,
  },
];

export default liberalitycreationlatexRoutes;
