import { lazy } from "react";

const AccountList = lazy(() => import("./AccountList"));

const accountRoutes = [
  {
    path: "/account/list",
    component: AccountList,
  },
];
export default accountRoutes;
