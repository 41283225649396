import React from 'react';
import { Redirect } from 'react-router-dom';
import dashboardRoutes from './views/dashboard/dashboardRoutes';
import uiKitsRoutes from './views/ui-kits/uiKitsRoutes';
import formsRoutes from './views/forms/formsRoutes';
import sessionsRoutes from './views/sessions/sessionsRoutes';
import AuthGuard from './auth/AuthGuard';
import widgetsRoute from './views/widgets/widgetsRoute';
import chartsRoute from './views/charts/chartsRoute';
import dataTableRoute from './views/dataTable/dataTableRoute';
import extraKitsRoutes from './views/extra-kits/extraKitsRoutes';
import pagesRoutes from './views/pages/pagesRoutes';
import iconsRoutes from './views/icons/iconsRoutes';
import invoiceRoutes from './views/app/invoice/invoiceRoutes';
import inboxRoutes from './views/app/inbox/inboxRoutes';
import chatRoutes from './views/app/chat/chatRoutes';
import calendarRoutes from './views/app/calendar/calendarRoutes';
import taskManagerRoutes from './views/app/fieldCollectionData/taskManagerRoutes';
import collectionRoutes from './views/app/rubberCollectionData/colletionRoutes';
import ecommerceRoutes from './views/app/ecommerce/ecommerceRoutes';
import contactRoutes from './views/app/contact/contactRoutes';
import supplierRoutes from './views/app/supplierMaintenance/supplierRoutes';
import companyRoutes from './views/app/companyMaintenance/companyRoutes';
import routeRoutes from './views/app/routeCreation/routeRoutes';
import batchRoutes from './views/app/batch/batchRoutes';
import locationRoutes from './views/app/locationMaintenance/locationRoutes';
import companyadminRoutes from './views/app/companyadmin/companyadminRoutes';
import assigncompanyRoutes from './views/app/assigncompany/assigncompanyRoutes';
import assignsuppliersRoutes from './views/app/assignsuppliers/assignsuppliersRoutes';
import picitemRoutes from './views/app/itemMaintenance/picitemRoutes';
import assignLatexRoutes from './views/app/routeSupplierAssign/assignLatexRoutes';
import accountRoutes from './views/app/accountBatchUpdate/accountRoutes';
import labRoutes from './views/app/labTestSummary/labRoutes';
import backPicItemRoutes from './views/app/backpicitem/backPicItemRoutes';

import userRoutes from './views/app/userCreation/userRoutes';
import userConfigurationRoutes from './views/app/userConfiguration/userConfigurationRoutes';

import roleRoutes from './views/app/roleCreation/roleRoutes';
import formRoutes from './views/app/formCreation/formRoutes';

import selectCompanyRoutes from './views/app/selectCompany/selectCompanyRoutes';

import homeRoutes from './views/app/home/homeRoutes';

import assignLocationsUserRoutes from './views/app/assignLocationsUser/assignLocationsUserRoutes';

import changePasswordRoutes from './views/app/changepassword/changePasswordRoutes';

import finalDrcRoutes from './views/app/finalDrc/finalDrcRoutes';

import glPrimaryClasificationRoutes from './views/app/glPrimaryClasification/glPrimaryClasificationRoutes';

import glSubClasificsRoutes from './views/app/glSubClasifics/glSubClasificsRoutes';

import reportsRoutes from './views/app/reports/reportsRoutes';

import creditorsRoutes from './views/app/creditors/creditorsRoutes';

import reportRoutes from './views/app/report/reportRoutes';

import advancepaymentRoutes from './views/app/advancepayment/advancepaymentRoutes';

import processPaymnetRoutes from './views/app/processpaymnet/processPaymnetRoutes';

import batchCreationRoutes from './views/app/batchCreation/batchCreationRoutes';

import advancepaymentPayRoutes from './views/app/advancepaymentpay/advancepaymentPayRoutes';

import resetpasswordUserRoutes from './views/app/resetpassword/resetpasswordUserRoutes';

import chartofaccsRoutes from './views/app/chartofaccs/chartofaccsRoutes';

import journalentryRoutes from './views/app/journalentry/journalentryRoutes';

import purchaseorderRoutes from './views/app/purchaseorder/purchaseorderRoutes';

import purchaserequisitionRoutes from './views/app/purchaserequisition/purchaserequisitionRoutes';

import workflowRoutes from './views/app/workflow/workflowRoutes';
import approverWorkFlowRoutes from './views/app/approverworkflow/approverWorkFlowRoutes';

import workflowInfoRoutes from './views/app/workflowinfo/workflowInfoRoutes';

import approverpoRoutes from './views/app/approverpo/approverpoRoutes';

import grnRoutes from './views/app/grn/grnRoutes';
import materialissuenoteRoutes from './views/app/materialissuenote/materialissuenoteRoutes';

import gtnoRoutes from './views/app/gtno/gtnoRoutes';

import grnnopoRoutes from './views/app/grnnopo/grnnopoRoutes';

import grnliabilitytRoutes from './views/app/grnliability/grnliabilityRoutes';

import assignSupRoutes from './views/app/assignsup/assignSupRoutes';

import latexrateListRoutes from './views/app/latexrate/latexrateListRoutes';

import finalPaymentRoutes from './views/app/finalpayment/finalPaymentRoutes';

import prtopoRoutes from './views/app/prtopo/prtopoRoutes';

import prApprover from './views/app/prapprover/prApprover';

import dispatchnoteRoutes from './views/app/dispatchnote/dispatchnoteRoutes';

import finishedgoodinRoutes from './views/app/finishedgoodin/finishedgoodinRoutes';

import approversupplierRoutes from './views/app/approversupplier/approversupplierRoutes';

import viewsupplier from './views/app/viewsupplier/viewSupplierRoutes';

import creditInvoiceRoutes from './views/app/creditinvoice/creditInvoiceRoutes';

import salesorderRoutes from './views/app/salesorder/salesorderRoutes';

import fielddetailsRoutes from './views/app/fielddetails/fielddetailsRoutes';

import farmermapRoutes from './views/app/farmermap/farmermapRoutes';

import pettycashRoutes from './views/app/pettycash/pettycashRoutes';

import iouRoutes from './views/app/iou/iouRoutes';

import pettycashpaymnetRoutes from './views/app/pettycashpaymnet/pettycashpaymnetRoutes';

import smsRoutes from './views/app/sms/smsRoutes';

import processpaymnetpettycashRoutes from './views/app/processpaymnetpettycash/processpaymnetpettycashRoutes';

import ledgeracchistoryRoutes from './views/app/ledgeracchistory/ledgeracchistoryRoutes';

import recRoutes from './views/app/rec/recRoutes';

import setoffRoutes from './views/app/setoff/setoffRoutes';

import productgraderateRoutes from './views/app/productgraderate/productgraderateRoutes';

import supplierwiseRoutes from './views/app/supplierwise/supplierwiseRoutes';

import fundtransferRoutes from './views/app/fundtransfer/fundtransferRoutes';

import bttankdrcRoutes from './views/app/bttankdrc/bttankdrcRoutes';

import bttankchartRoutes from './views/app/bttankchart/bttankchartRoutes';

import stprodinRoutes from './views/app/stprodin/stprodinRoutes';

import receiptreverseRoutes from './views/app/receiptreverse/receiptreverseRoutes';

import tankdrcRoutes from './views/app/tankdrc/tankdrcRoutes';

import labvfaRoutes from './views/app/labvfa/labvfaRoutes';

import wipskiminRoutes from './views/app/wipskimin/wipskiminRoutes';

import waterremoveRoutes from './views/app/waterremove/waterremoveRoutes';

import organicallocationRoutes from './views/app/organicallocation/organicallocationRoutes';

import latexdispatchRoutes from './views/app/latexdispatch/latexdispatchRoutes';

import btvfadrcupdRoutes from './views/app/btvfadrcupd/btvfadrcupdRoutes';

import supchartRoutes from './views/app/supchart/supchartRoutes';

import stocktransferRoutes from './views/app/stocktransfer/stocktransferRoutes';

import chqprintRoutes from './views/app/chqprint/chqprintRoutes';

import poingRoutes from './views/app/poing/poingRoutes';

import orderbookRoutes from './views/app/orderbook/orderbookRoutes';

import latexgrnRoutes from './views/app/latexgrn/latexgrnRoutes';

import barcodeRoutes from './views/app/barcode/barcodeRoutes';

import liberalitycreationlatexRoutes from './views/app/liberalitycreationlatex/liberalitycreationlatexRoutes';

import prodPlanningRoutes from './views/app/prodplanning/prodPlanningRoutes';
import productionPlanningRoutes from './views/app/productionplanning/productionplanningRoutes';

import JobCreationRoutes from './views/app/jobcreation/JobCreationRoutes';

import fillingRoutes from './views/app/filling/fillingRoutes';

import dryerRoutes from './views/app/dryer/dryerRoutes';

import pettyCashListeningRoutes from './views/app/pettycashlistening/pettyCashListeningRoutes';

import confirmProductionRoutes from './views/app/confirmproduction/confirmProductionRoutes';

import btreconciliationRoutes from './views/app/btreconciliation/btreconciliationRoutes';

import grnListingRoutes from './views/app/grnlisting/grnListingRoutes';

import orderCompletionRoutes from './views/app/orderCompletion/orderCompletionRoutes';

import prodPlanningTwoRoutes from './views/app/prodplanningtwo/prodPlanningTwoRoutes';

import reprintRoutes from './views/app/reprint/reprintRoutes';

import prodPlanningBeforeRoutes from './views/app/prodplanningbefore/prodPlanningBeforeRoutes';

import dryerinputRoutes from './views/app/dryerinput/dryerinputRoutes';

import prodplanninglastRoutes from './views/app/prodplanninglast/prodplanninglastRoutes';

import dryeroutputRoutes from './views/app/dryeroutput/dryeroutputRoutes';

import barcodemulitiRoutes from './views/app/barcodemuliti/barcodemulitiRoutes';

import fabricationfinalRoutes from './views/app/fabricationfinal/fabricationfinalRoutes';

import stdtprodRoutes from './views/app/stdtprod/stdtprodRoutes';

import waterbillRoutes from './views/app/waterbill/waterbillRoutes';

import fabricationfinalloadRoutes from './views/app/fabricationfinalload/fabricationfinalloadRoutes';

import dryeroutputreportRoutes from './views/app/dryeroutputreport/dryeroutputreportRoutes';

import reprintnewRoutes from './views/app/reprintnew/reprintnewRoutes';

import fabricationwastageRoutes from './views/app/fabricationwastage/fabricationwastageRoutes';

import vesselRoutes from './views/app/vessel/vesselRoutes';

import vesselreportRoutes from './views/app/vesselreport/vesselreportRoutes';

import pendingSalesRoutes from './views/app/pendingSales/pendingSalesRoutes';

import fabricationwipmonitoringRoutes from './views/app/fabricationwipmonitoring/fabricationwipmonitoringRoutes';

import fgstockRoutes from './views/app/fgstock/fgstockRoutes';

import fgstockorderRoutes from './views/app/fgstockorder/fgstockorderRoutes';

import xlsxRoutes from './views/app/xlsx/xlsxRoutes';

import vpdinvRoutes from './views/app/vpdinv/vpdinvRoutes';
import rlaRoutes from './views/app/rla/rlaRoutes';

import approverworkflowrla from './views/app/approverworkflowrla/approverworkflowrlaRoutes';

import approvelatexRoutes from './views/app/approvelatex/approvelatexRoutes';

import revaluationRoutes from './views/app/revaluation/revaluationRoutes';

import orderbooknewRoutes from './views/app/orderbooknew/orderbooknewRoutes';

import pinyearRoutes from './views/app/pinyear/pinyearRoutes';

import minsRoutes from './views/app/mins/minsRoutes';

import findashRoutes from './views/app/findash/findashRoutes';

import debitnoteRoutes from './views/app/debitnote/debitnoteRoutes';

import tranportRoutes from './views/app/tranport/tranportRoutes';

import bankrecRoutes from './views/app/bankrec/bankrecRoutes';

import qrRoutes from './views/app/qr/qrRoutes';

import gtnolotRoutes from './views/app/gtnolot/gtnolotRoutes';

import dieselRoutes from './views/app/diesel/dieselRoutes';

import verticalcutterRoutes from './views/app/verticalcutter/verticalcutterRoutes';

import horizontalcutterRoutes from './views/app/horizontalcutter/horizontalcutterRoutes';

import bttobtRoutes from './views/app/bttobt/bttobtRoutes';

import dieselupdateRoutes from './views/app/dieselupdate/dieselupdateRoutes';

import matresscoverRoutes from './views/app/matresscover/matresscoverRoutes';

import bankbBlancesRoutes from './views/app/bankbalances/bankbBlancesRoutes';

import materialissuenotereturn from './views/app/materialissuenotereturn/materialissuenoteRoutes';

import loadingSheetRoutes from './views/app/loadingSheet/loadingSheetRoutes';

import orderCompletionRoutesNew from './views/app/orderCompletionnew/orderCompletionRoutesNew';

import orderCompletionnewseqRoutesNew from './views/app/orderCompletionnewseq/orderCompletionnewseqRoutesNew';

import productionStockAdjustmentRoutes from './views/app/productionStockAdjustment/productionStockAdjustmentRoutes';

import debtorsageanalysisRoutes from './views/app/debtorsageanalysis/debtorsageanalysisRoutes';

import chartxslRoutes from './views/app/chartxsl/chartxslRoutes';

import localinvoiceRoutes from './views/app/localinvoice/localinvoiceRoutes';

import dnoteRoutes from './views/app/dnote/dnoteRoutes';
import cnoteRoutes from './views/app/cnote/cnoteRoutes';

import maRoutes from './views/app/ma/maRoutes';

import verticalRecutterRoutes from './views/app/verticalRecutter/verticalRecutterRoutes';

import recutRoutes from './views/app/recut/recutRoutes';

import wipinquiryRoutes from './views/app/wipinquiry/wipinquiryRoutes';

import purchaserequisitionviewRoutes from './views/app/purchaserequisitionview/purchaserequisitionviewRoutes';

import sampleRoutes from './views/app/sample/sampleRoutes';

import orderflowrlaRoutes from './views/app/orderflowrla/orderflowrlaRoutes';

import approvelsampleRoutes from './views/app/approvesample/approvelsampleRoutes';

import mvdrRoutes from './views/app/mvdr/mvdrRoutes';

import pettycashrenewRoutes from './views/app/pettycashrenew/pettycashrenewRoutes';

import pettycashreRoutes from './views/app/pettycashre/pettycashreRoutes';

import pettycashreapproewRoutes from './views/app/pettycashreapproew/pettycashreRoutes';

import ordercompletionV2Routes from './views/app/orderCompletionV2/ordercompletionV2Routes';

import fgbarcodeRoutes from './views/app/fgbarcode/fgbarcodeRoutes';

import recutnewRoutes from './views/app/recutnew/recutnewRoutes';

import hrRoutes from './views/app/hr/hrRoutes';

import hrviewRoutes from './views/app/hrview/hrviewRoutes';

import prApproverNew from './views/app/prapprovernew/prApprovernew';

import prtoponewRoutes from './views/app/prtoponew/prtoponewRoutes';

import pricemoduleRoutes from './views/app/pricemodule/pricemoduleRoutes';

import fgbarcodevhRoutes from './views/app/fgbarcodevh/fgbarcodevhRoutes';

import avuploadRoutes from './views/app/avupload/avuploadRoutes';

import adpmpayprocessRoutes from './views/app/adpmpayprocess/adpmpayprocessRoutes';

import efchartRoutes from './views/app/efchart/efchartRoutes';

import gdriveRoutes from './views/app/gdrive/gdriveRoutes';

import uploadtypeRoutes from './views/app/uploadtype/uploadtypeRoutes';

import whatsappRoutes from './views/app/whatsapp/whatsappRoutes';

import productRoutes from './views/app/product/productRoutes';

import pheadRoutes from './views/app/phead/pheadRoutes';

import salesreturnRoutes from './views/app/salesreturn/salesreturnRoutes';

import apdrupRoutes from './views/app/apdrup/apdrupRoutes';
import pillowFillingRoutes from './views/app/pillowFilling/pillowRoutes';
import pillowDryoutputRoutes from './views/app/pillowDryOutput/pillowDryOutputRoutes';
import bulkSmsRoutes from './views/app/bulksms/bulkSmsRoutes';
import fabricatorRoutes from './views/app/fabricators/fabricatorRoutes';
import stdtProdPillowRoutes from './views/app/stdtprodpillow/stdtprodPillowRoutes';
import fabTableRoutes from './views/app/fabTable/routes';
import fabTableAllocationRoutes from './views/app/fabTableAllocation/routes';
import ronRoutes from './views/app/ron/grnnopoRoutes';
import customerReturnRoutes from './views/app/customerReturn/customerReturnRoutes';
import fabricationfinalloadlocalRoutes from './views/app/fabricationfinalloadlocal/fabricationfinalloadRoutes';
import fgreceivednoteRoutes from './views/app/fgreceivednote/fgreceivednoteRoutes';
import pillowQCRoutes from './views/app/pillowQC/pillowQCRoutes';
import tranHeaderRoutes from './views/app/tranheader/tranHeaderRoutes';
import posinvoiceRoutes from './views/app/posinvoice/posinvoiceRoutes';
import transferToLocalRoutes from './views/app/transferToLocal/transferToLocalRoutes';
import fabricationRoutes from './views/app/fabrication/fabricationRoutes';
import bulkPrintRoutes from './views/app/bulkprint/bulkPrintRoutes';
import asprRoutes from './views/app/aspr/asprRoutes';
import grnlatex from './views/app/grnlatex/grnnopoRoutes';
import mrilatex from './views/app/mrilatex/materialissuenoteRoutes';
import vehicleRoutes from './views/app/vehicle/vehicleRoutes';
import orderExportRoutes from './views/app/orderExport/orderExportRoutes';
import priceUpdateByCustomerRoute from './views/app/priceupdatebycustomer/priceUpdateByCustomerRoute';
import priceUpdateApproval from './views/app/priceUpdateApproval/PriceUpdateApprovalRoute';
import BulkSmsCustomMessageRoutes from './views/app/bulkSmsCustomMessage/bulkSmsCustomMessageRoutes';
import sampleApprovalRoutes from './views/app/sampleApproval/sapleApprovalRoutes';
import assetGroupManagerRoutes from './views/app/assetgroupmanager/assetGroupManagerRoutes';
import fixedassetsmasterRoutes from './views/app/fixedassetsmaster/fixedassetsmasterRoutes';
import supplierQRGeneratorRoutes from './views/app/supplierqrgenerator/supplierqrgeneratorRoutes';
import collectionmapRoutes from './views/app/collectionmap/collectionmapRoutes';
import localwipRoutes from './views/app/localwip/localwipRoutes';
import QcItemRejectionRoutes from './views/app/qcitemrejection/qcitemrejectionRoutes';
import OrderCompletionPillowRoutes from './views/app/orderCompletionPillow/orderCompletionPillowRoutes';
import localFGRoutes from './views/app/localfg/localfgRoutes';
import paymentsetoffRoutes from './views/app/paymentsetoff/paymentsetoffRoutes';
import mealissueRoutes from './views/app/mealissue/mealissueRoutes';
import PillowOrderConvertRoutes from './views/app/pilloworderconvert/pilloworderconvertRoutes';
import hexagonStockRoutes from './views/app/hexagonstock/hexagonstocksRoutes';
import latexconsumptionRoutes from './views/app/latexconsumption/latexconsumptionRoutes';
import productionentrychartsRoutes from './views/app/productionentrycharts/productionentrychartsRoutes';
import fieldvisitRoutes from './views/app/fieldvisit/fieldVisitRoutes';
import latexarrivalRoutes from './views/app/latexarrival/latexarrivalRoutes';
import cyclecountRoutes from './views/app/cyclecount/cyclecountRoutes';
import cyclecountupdateRoutes from './views/app/cyclecountupdate/cyclecountupdateRoutes';
import cyclecountpostingRoutes from './views/app/cyclecountposting/cyclecountpostingRoutes';
import chartDashboardRoutes from './views/app/dashboard/dashboardRoutes';
import barcodeprinterRoutes from './views/app/barcodeprinter/barcodeprinterRoutes';
import empregistrationRoutes from './views/app/hrsystem/empregistration/empregistrationRoutes';
import PurchOrdInquiryRoutes from './views/app/purchordinq/PurchOrdInquiryRoutes';
import wipRoutes from './views/app/Wip/WipRoutes';
import OrderTransferRoutes from './views/app/ordertransfer/OrderTransferRoutes';
import NewSalesOrderRoutes from './views/app/newSalesOrder/NewSalesOrderRoutes';
import PaymentAttViewRoutes from './views/app/paymnetAttachView/PaymentAttViewRoutes';
import LtxPriceUpdateNewRoutes from './views/app/ltxpriceupdatenew/LtxPriceUpdateNewRoutes';
import ProdIncentiveRoutes from './views/app/productionIncentive/ProdIncentiveRoutes';
import ProdIncentiveViewRoutes from './views/app/prodincentiveview/ProdIncentiveViewRoutes';
import EmployeeCountRoutes from './views/app/employeecount/EmployeeCountRoutes';
import orderqramendmentRoutes from './views/app/orderqramendment/orderqramendmentRoutes';
import ltxsuppayprocRoutes from './views/app/ltxsuppayproc/ltxsuppayprocRoutes';
import ltxpriceexportRoutes from './views/app/ltxpriceexport/ltxpriceexportRoutes';
import SupPaymentInqRoutes from './views/app/suppaymentinq/suppaymentinqRoutes';
import PillowStockInqRoutes from './views/app/pillowstockinq/pillowstockinqRoutes';
import bomRoutes from './views/app/bom/bomRoutes';
import LaminationRoutes from './views/app/lamination/laminationRoutes';
import fgbominRoutes from './views/app/fgbomin/fgbominRoutes';
import itemrewipRoutes from './views/app/itemrewip/itemrewipRoutes';
import WipInRoutes from './views/app/WIPIN/WipInRoutes';
import setupRoutes from './views/app/hrsystem/setup/setupRoutes';
import calRoutes from './views/app/hrsystem/calender/calRoutes';
import grpRoutes from './views/app/hrsystem/grpallocation/grpalloRoutes';
import MisRoutes from './views/app/mis/MisRoutes';
import SmsPaymentRoutes from './views/app/smsPayment/SmsPaymentRoutes';
import manualVoucherEfRoutes from './views/app/manualvoucheref/manuVoucherEfRoutes';
import OrderCompletionInqRoutes from './views/app/ordercompletioninq/ordercompletioninqRoutes';
import barcodeInqRoutes from './views/app/barcodeInq/barcodeInqRoutes';
import leaveRoutes from './views/app/hrsystem/empleave/leaveRoutes';
// import dummytransferRoutes from './views/app/dummysuptransfer/dummytransferRoutes';
import farmerQrCreationRoutes from './views/app/farmerQrCreation/farmerQrCreationRoutes';
import OrderCompletionV3Routes from './views/app/OrdercompletionV3/OrdercompletionV3Routes';
import newReportsRoutes from './views/app/newReports/newReportsRoutes';
import dummyTransferRoutes from './views/app/dummyTransfer/dummyTransferRoutes';
import dummyTransferApprovalRoutes from './views/app/dummyTransferApproval/dummyTransferApprovalRoutes';
import batchAmendmentRoutes from './views/app/batchAmendment/batchAmendmentRoutes';
import mouldGradingRoutes from './views/app/mouldGrading/mouldGradingRoutes';
import mouldRoutes from './views/app/mould/mouldRoutes';
import supplierInquiryRoutes from './views/app/supplierInquiry/supplierInquiryRoutes';
import mouldRepairRoutes from './views/app/mouldRepair/mouldRepairRoutes';
import priceUpdateBatchItemPicItemIdRoutes from './views/app/priceUpdateBatchItemPicItemId/priceUpdateBatchItemPicItemIdRoutes';
import localSalesNewRoutes from './views/app/localSalesNew/localSalesNewRoutes';
import orderProfitabilityRoutes from './views/app/orderProfitability/orderProfitabilityRoutes';
import orderApprovalRoutes from './views/app/orderApproval/orderApprovalRoutes';
import fileAttUploadRoutes from './views/app/fileAttUpload/fileAttUploadRoutes';
import orderfginqRoutes from './views/app/orderfginq/orderfginqRoutes';
import mouldTransferRoutes from './views/app/mouldTransfer/mouldTransferRoutes';
import TimeSheetRoutes from './views/app/hrsystem/timesheet/TimeSheetRoutes';
import timeAttendanceRoutes from './views/app/hrsystem/timeAttendance/timeAttendanceRoutes';
import shiftRoutes from './views/app/hrsystem/shiftallocation/shiftallocationRoutes';
import sampleQrRoutes from './views/app/sampleqr/sampleQrRoutes';
import adpmbulkprocRoutes from './views/app/adpmbulkproc/adpmbulkprocRoutes';
import addotappRoutes from './views/app/addotapp/addotappRoutes';
import fgsumRoutes from './views/app/fgsum/fgsumRoutes';
import receiptupldRoutes from './views/app/receiptupld/receiptupldRoutes';
import hrsalinqRoutes from './views/app/hrsalinq/hrsalinqRoutes';
import empRegNewRoutes from './views/app/hrsystem/empRegNew/empRegNewRoutes';
import ltxsampleinqRoutes from './views/app/ltxsampleinq/ltxsampleinqRoutes';
import shoesoleRoutes from './views/app/shoesole/shoesoleRoutes';
import priceinquiryUpdateRoutes from './views/app/priceinquiryUpdate/priceinquiryUpdateRoutes';
import dashboardEngRoutes from './views/app/dashboardeng/dashboardEngRoutes';
import downTimeRoutes from './views/app/downtime/downtimeRoutes';
import latexSupplierPaymentInquiryRoutes from './views/app/latexSupplierPaymentInquiry/latexSupplierPaymentInquiryRoutes';
import cashmgmntRoutes from './views/app/sop/cash-management/cashmgmntRoutes';
import showRoomRoutes from './views/app/sop/showroom/showroomRoutes';
import purchasingproceduresRoutes from './views/app/sop/purchasing-procedures/purchasing-proceduresRoutes';
import ordrecRoutes from './views/app/sop/ord-rec/ord-recRoutes';
import latexpurchasingRoutes from './views/app/sop/latex-purchasing/latex-purchasingRoutes';
import inventoryManagementRoutes from './views/app/sop/inventory-management/inventory-managementRoutes';
import dashboardMarRoutes from './views/app/dashboardMar/dashboardMarRoutes';
import corporateReportRoutes from './views/app/coporateReport/corporateReportRoutes';
import DashboardPurchRoutes from './views/app/dashboardPurch/dashboardPurchRoutes';
import engchecklistRoutes from './views/app/engchecklist/engchecklistRoutes';
import prodPlanRoutes from './views/app/prodPlan/prodPlanRoutes';
import dashboardPlanningRoutes from './views/app/dashboardPlanning/dashboardPlanningRoutes';
import ltxcollecauditRoutes from './views/app/ltxcollecaudit/ltxcollecauditRoutes';
import BankBalanceRoutes from './views/app/bnkbal/BankBalanceRoutes';
import dashboardFinanRoutes from './views/app/dashboardFinan/dashboardFinanRoutes';
import loangivenRoutes from './views/app/loangiven/loangivenRoutes';
import loanborrowedRoutes from './views/app/loanborrowed/loanborrowedRoutes';
import orderUpdateRoutes from './views/app/orderUpdate/orderUpdateRoutes';
import debtorsAgeingRoutes from './views/app/debtorsAgeing/debtorsAgeingRoutes';
import orderPreApprovalRoutes from './views/app/orderPreApproval/orderPreApprovalRoutes';
import sceneRoutes from './views/app/scene/sceneRoutes';
import dashLabRoutes from './views/app/dashLab/dashLabRoutes';
import ltxmatissueRoutes from './views/app/ltxmatissue/ltxmatissueRoutes';

const redirectRoute = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...sessionsRoutes,
  ...fabricatorRoutes,
  {
    path: '/',
    component: AuthGuard,
    routes: [
      ...vehicleRoutes,
      ...grnlatex,
      ...asprRoutes,
      ...mrilatex,
      ...bulkPrintRoutes,
      ...fabricationRoutes,
      ...transferToLocalRoutes,
      ...posinvoiceRoutes,
      ...customerReturnRoutes,
      ...tranHeaderRoutes,
      ...pillowQCRoutes,
      ...fgreceivednoteRoutes,
      ...fabricationfinalloadlocalRoutes,
      ...bulkSmsRoutes,
      ...fabTableRoutes,
      ...ronRoutes,
      ...fabTableAllocationRoutes,
      ...apdrupRoutes,
      ...salesreturnRoutes,
      ...stdtProdPillowRoutes,
      ...pheadRoutes,
      ...pillowFillingRoutes,
      ...pillowDryoutputRoutes,
      ...productRoutes,
      ...whatsappRoutes,
      ...uploadtypeRoutes,
      ...gdriveRoutes,
      ...efchartRoutes,
      ...adpmpayprocessRoutes,
      ...avuploadRoutes,
      ...fgbarcodevhRoutes,
      ...pricemoduleRoutes,
      ...prtoponewRoutes,
      ...prApproverNew,
      ...hrviewRoutes,
      ...hrRoutes,
      ...recutnewRoutes,
      ...fgbarcodeRoutes,
      ...ordercompletionV2Routes,
      ...pettycashreapproewRoutes,
      ...pettycashreRoutes,
      ...pettycashrenewRoutes,
      ...mvdrRoutes,
      ...approvelsampleRoutes,
      ...orderflowrlaRoutes,
      ...sampleRoutes,
      ...purchaserequisitionviewRoutes,
      ...wipinquiryRoutes,
      ...recutRoutes,
      ...verticalRecutterRoutes,
      ...maRoutes,
      ...cnoteRoutes,
      ...dnoteRoutes,
      ...localinvoiceRoutes,
      ...chartxslRoutes,
      ...debtorsageanalysisRoutes,
      ...productionStockAdjustmentRoutes,
      ...orderCompletionnewseqRoutesNew,
      ...orderCompletionRoutesNew,
      ...loadingSheetRoutes,
      ...materialissuenotereturn,
      ...bankbBlancesRoutes,
      ...matresscoverRoutes,
      ...dieselupdateRoutes,
      ...bttobtRoutes,
      ...horizontalcutterRoutes,
      ...verticalcutterRoutes,
      ...dieselRoutes,
      ...gtnolotRoutes,
      ...qrRoutes,
      ...bankrecRoutes,
      ...tranportRoutes,
      ...debitnoteRoutes,
      ...findashRoutes,
      ...minsRoutes,
      ...pinyearRoutes,
      ...orderbooknewRoutes,
      ...revaluationRoutes,
      ...approvelatexRoutes,
      ...approverworkflowrla,
      ...rlaRoutes,
      ...vpdinvRoutes,
      ...xlsxRoutes,
      ...fgstockorderRoutes,
      ...fgstockRoutes,
      ...fabricationwipmonitoringRoutes,
      ...pendingSalesRoutes,
      ...vesselreportRoutes,
      ...vesselRoutes,
      ...fabricationwastageRoutes,
      ...reprintnewRoutes,
      ...dryeroutputreportRoutes,
      ...fabricationfinalloadRoutes,
      ...waterbillRoutes,
      ...stdtprodRoutes,
      ...fabricationfinalRoutes,
      ...barcodemulitiRoutes,
      ...dryeroutputRoutes,
      ...prodplanninglastRoutes,
      ...dryerinputRoutes,
      ...prodPlanningBeforeRoutes,
      ...reprintRoutes,
      ...prodPlanningTwoRoutes,
      ...orderCompletionRoutes,
      ...latexgrnRoutes,
      ...grnListingRoutes,
      ...btreconciliationRoutes,
      ...confirmProductionRoutes,
      ...pettyCashListeningRoutes,
      ...dryerRoutes,
      ...fillingRoutes,
      ...JobCreationRoutes,
      ...prodPlanningRoutes,
      ...liberalitycreationlatexRoutes,
      ...barcodeRoutes,
      ...orderbookRoutes,
      ...poingRoutes,
      ...chqprintRoutes,
      ...stocktransferRoutes,
      ...supchartRoutes,
      ...btvfadrcupdRoutes,
      ...latexdispatchRoutes,
      ...organicallocationRoutes,
      ...waterremoveRoutes,
      ...wipskiminRoutes,
      ...labvfaRoutes,
      ...tankdrcRoutes,
      ...receiptreverseRoutes,
      ...stprodinRoutes,
      ...bttankchartRoutes,
      ...bttankdrcRoutes,
      ...fundtransferRoutes,
      ...supplierwiseRoutes,
      ...productgraderateRoutes,
      ...setoffRoutes,
      ...recRoutes,
      ...ledgeracchistoryRoutes,
      ...processpaymnetpettycashRoutes,
      ...smsRoutes,
      ...pettycashpaymnetRoutes,
      ...iouRoutes,
      ...pettycashRoutes,
      ...farmermapRoutes,
      ...fielddetailsRoutes,
      ...salesorderRoutes,
      ...creditInvoiceRoutes,
      ...viewsupplier,
      ...approversupplierRoutes,
      ...finishedgoodinRoutes,
      ...dispatchnoteRoutes,
      ...prApprover,
      ...prtopoRoutes,
      ...finalPaymentRoutes,
      ...latexrateListRoutes,
      ...assignSupRoutes,
      ...grnliabilitytRoutes,
      ...grnnopoRoutes,
      ...gtnoRoutes,
      ...materialissuenoteRoutes,
      ...grnRoutes,
      ...approverpoRoutes,
      ...workflowInfoRoutes,
      ...approverWorkFlowRoutes,
      ...workflowRoutes,
      ...purchaserequisitionRoutes,
      ...purchaseorderRoutes,
      ...journalentryRoutes,
      ...chartofaccsRoutes,
      ...resetpasswordUserRoutes,
      ...advancepaymentPayRoutes,
      ...batchCreationRoutes,
      ...processPaymnetRoutes,
      ...advancepaymentRoutes,
      ...reportRoutes,
      ...creditorsRoutes,
      ...reportsRoutes,
      ...glSubClasificsRoutes,
      ...glPrimaryClasificationRoutes,
      ...finalDrcRoutes,
      ...changePasswordRoutes,
      ...assignLocationsUserRoutes,
      ...homeRoutes,
      ...selectCompanyRoutes,
      ...userConfigurationRoutes,
      ...roleRoutes,
      ...formRoutes,
      ...dashboardRoutes,
      ...supplierRoutes,
      ...companyRoutes,
      ...companyadminRoutes,
      ...assignsuppliersRoutes,
      ...assigncompanyRoutes,
      ...picitemRoutes,
      ...accountRoutes,
      ...labRoutes,
      ...userRoutes,
      ...routeRoutes,
      ...batchRoutes,
      ...locationRoutes,
      ...assignLatexRoutes,
      ...backPicItemRoutes,
      ...uiKitsRoutes,
      ...formsRoutes,
      ...widgetsRoute,
      ...chartsRoute,
      ...dataTableRoute,
      ...extraKitsRoutes,
      ...pagesRoutes,
      ...iconsRoutes,
      ...invoiceRoutes,
      ...inboxRoutes,
      ...chatRoutes,
      ...taskManagerRoutes,
      ...collectionRoutes,
      ...calendarRoutes,
      ...ecommerceRoutes,
      ...contactRoutes,
      ...redirectRoute,
      ...productionPlanningRoutes,
      ...orderExportRoutes,
      ...priceUpdateByCustomerRoute,
      ...priceUpdateApproval,
      ...BulkSmsCustomMessageRoutes,
      ...sampleApprovalRoutes,
      ...assetGroupManagerRoutes,
      ...fixedassetsmasterRoutes,
      ...supplierQRGeneratorRoutes,
      ...collectionmapRoutes,
      ...localwipRoutes,
      ...QcItemRejectionRoutes,
      ...OrderCompletionPillowRoutes,
      ...localFGRoutes,
      ...paymentsetoffRoutes,
      ...mealissueRoutes,
      ...PillowOrderConvertRoutes,
      ...hexagonStockRoutes,
      ...latexconsumptionRoutes,
      ...productionentrychartsRoutes,
      ...fieldvisitRoutes,
      ...latexarrivalRoutes,
      ...cyclecountRoutes,
      ...cyclecountupdateRoutes,
      ...cyclecountpostingRoutes,
      ...chartDashboardRoutes,
      ...barcodeprinterRoutes,
      ...empregistrationRoutes,
      ...PurchOrdInquiryRoutes,
      ...wipRoutes,
      ...OrderTransferRoutes,
      ...NewSalesOrderRoutes,
      ...PaymentAttViewRoutes,
      ...LtxPriceUpdateNewRoutes,
      ...ProdIncentiveRoutes,
      ...ProdIncentiveViewRoutes,
      ...EmployeeCountRoutes,
      ...orderqramendmentRoutes,
      ...ltxsuppayprocRoutes,
      ...ltxpriceexportRoutes,
      ...SupPaymentInqRoutes,
      ...PillowStockInqRoutes,
      ...bomRoutes,
      ...LaminationRoutes,
      ...fgbominRoutes,
      ...itemrewipRoutes,
      ...calRoutes,
      ...setupRoutes,
      ...grpRoutes,
      ...SmsPaymentRoutes,
      ...MisRoutes,
      ...WipInRoutes,
      ...manualVoucherEfRoutes,
      ...OrderCompletionInqRoutes,
      ...barcodeInqRoutes,
      ...leaveRoutes,
      // ...dummytransferRoutes,
      ...farmerQrCreationRoutes,
      ...OrderCompletionV3Routes,
      ...newReportsRoutes,
      ...dummyTransferRoutes,
      ...dummyTransferApprovalRoutes,
      ...batchAmendmentRoutes,
      ...mouldGradingRoutes,
      ...mouldRoutes,
      ...supplierInquiryRoutes,
      ...mouldRepairRoutes,
      ...priceUpdateBatchItemPicItemIdRoutes,
      ...localSalesNewRoutes,
      ...orderProfitabilityRoutes,
      ...orderApprovalRoutes,
      ...fileAttUploadRoutes,
      ...orderfginqRoutes,
      ...mouldTransferRoutes,
      ...TimeSheetRoutes,
      ...timeAttendanceRoutes,
      ...shiftRoutes,
      ...sampleQrRoutes,
      ...adpmbulkprocRoutes,
      ...addotappRoutes,
      ...fgsumRoutes,
      ...receiptupldRoutes,
      ...hrsalinqRoutes,
      ...empRegNewRoutes,
      ...ltxsampleinqRoutes,
      ...shoesoleRoutes,
      ...priceinquiryUpdateRoutes,
      ...dashboardEngRoutes,
      ...downTimeRoutes,
      ...latexSupplierPaymentInquiryRoutes,
      ...cashmgmntRoutes,
      ...showRoomRoutes,
      ...purchasingproceduresRoutes,
      ...ordrecRoutes,
      ...latexpurchasingRoutes,
      ...inventoryManagementRoutes,
      ...dashboardMarRoutes,
      ...corporateReportRoutes,
      ...DashboardPurchRoutes,
      ...engchecklistRoutes,
      ...prodPlanRoutes,
      ...dashboardPlanningRoutes,
      ...ltxcollecauditRoutes,
      ...BankBalanceRoutes,
      ...dashboardFinanRoutes,
      ...loangivenRoutes,
      ...loanborrowedRoutes,
      ...orderUpdateRoutes,
      ...debtorsAgeingRoutes,
      ...orderPreApprovalRoutes,
      ...sceneRoutes,
      ...dashLabRoutes,
      ...ltxmatissueRoutes,
      ...errorRoute
    ]
  }
];

export default routes;
