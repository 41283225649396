import { lazy } from "react";
const inventoryManagement = lazy(() => import("./inventory-management"));

const inventoryManagementRoutes = [
    {
        path : '/sop/inventory-management',
        component : inventoryManagement

    }
];

export default inventoryManagementRoutes;