import { lazy } from "react";

const Dnote = lazy(() => import("./Dnote"));

const dnoteRoutes = [
  {
    path: "/dnote",
    component: Dnote,
  },
];

export default dnoteRoutes;
