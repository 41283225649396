import { lazy } from "react";

const Productgraderate = lazy(() => import("./Productgraderate"));

const productgraderateRoutes = [
  {
    path: "/productgraderate",
    component: Productgraderate,
  },
];

export default productgraderateRoutes;
