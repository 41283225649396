import { lazy } from "react";

const FinalPaymentList = lazy(() => import("./FinalPaymentList"));

const finalPaymentRoutes = [
  {
    path: "/finalpayment",
    component: FinalPaymentList,
  },
];

export default finalPaymentRoutes;
