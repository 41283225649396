import { lazy } from "react";

const Rec = lazy(() => import("./Rec"));

const recRoutes = [
  {
    path: "/receipt",
    component: Rec,
  },
];

export default recRoutes;
