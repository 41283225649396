import { lazy } from "react";

const Waterremove = lazy(() => import("./Waterremove"));

const wipskiminRoutes = [
  {
    path: "/waterremove",
    component: Waterremove,
  },
];

export default wipskiminRoutes;
