import { lazy } from 'react';
const shoesole = lazy(() => import('./shoesole'));

const shoesoleRoutes = [
  {
    path: '/shoesole',
    component: shoesole
  }
];

export default shoesoleRoutes;