import { lazy } from 'react';
const debtorsAgeing = lazy(() => import('./debtorsAgeing'));

const debtorsAgeingRoutes = [
  {
    path: '/debtorsAgeing',
    component: debtorsAgeing
  }
];

export default debtorsAgeingRoutes;