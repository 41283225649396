import { lazy } from 'react';
const cashmgmnt = lazy(() => import('./cashmgmnt'));

const cashmgmntRoutes = [
  {
    path: '/sop/cash-management',
    component: cashmgmnt
  }
];

export default cashmgmntRoutes;
