import { lazy } from "react";

const Pettycash = lazy(() => import("./Pettycash"));

const pettycashRoutes = [
  {
    path: "/pettycash",
    component: Pettycash,
  },
];

export default pettycashRoutes;
