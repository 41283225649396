import { lazy } from 'react';
const localSalesNew = lazy(() => import('./localSalesNew'));

const localSalesNewRoutes = [
  {
    path: '/localSalesNew',
    component: localSalesNew
  }
];

export default localSalesNewRoutes;