import { lazy } from "react";

const BackPicItemRoutesList = lazy(() => import("./BackPicItemRoutesList"));

const backPicItemRoutes = [
  {
    path: "/backpicitem/list",
    component: BackPicItemRoutesList,
  },
];
export default backPicItemRoutes;