import { lazy } from "react";

const Efchart = lazy(() => import("./Efchart"));

const efchartRoutes = [
  {
    path: "/efchart",
    component: Efchart,
  },
];

export default efchartRoutes;
