import { lazy } from "react";

const Qr = lazy(() => import("./Qr"));

const qrRoutes = [
  {
    path: "/qr",
    component: Qr,
  },
];

export default qrRoutes;
