import { lazy } from "react";

const Chartxsl = lazy(() => import("./Chartxsl"));

const chartxslRoutes = [
  {
    path: "/chartxsl",
    component: Chartxsl,
  },
];

export default chartxslRoutes;
