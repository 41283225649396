import { lazy } from "react";

const UserConfiguration = lazy(() => import("./UserConfiguration"));

const userConfigurationRoutes = [
  {
    path: "/userconfiguration/list",
    component: UserConfiguration,
  },
];

export default userConfigurationRoutes;
