import { lazy } from "react";

const ProductionStockAdjustment = lazy(() => import("./ProductionStockAdjustment"));

const productionStockAdjustmentRoutes = [
  {
    path: "/productionstockadjustment",
    component: ProductionStockAdjustment,
  },
];

export default productionStockAdjustmentRoutes;
