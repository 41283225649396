import { lazy } from "react";

const Materialissuenotereturn = lazy(() => import("./Materialissuenotereturn"));

const materialissuenotereturn = [
  {
    path: "/materialissuenotereturn",
    component: Materialissuenotereturn,
  },
];

export default materialissuenotereturn;
