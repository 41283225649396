import { lazy } from 'react';
const empRegNew = lazy(() => import('./empRegNew'));

const empRegNewRoutes = [
  {
    path: '/hrsystem/empregnew',
    component: empRegNew
  }
];

export default empRegNewRoutes;
