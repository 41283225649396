import { lazy } from "react";

const Tankdrc = lazy(() => import("./Tankdrc"));

const tankdrcRoutes = [
  {
    path: "/tankdrc",
    component: Tankdrc,
  },
];

export default tankdrcRoutes;
