import { lazy } from 'react';
const mouldGrading = lazy(() => import('./mouldGrading'));

const mouldGradingRoutes = [
  {
    path: '/mouldgrading',
    component: mouldGrading
  }
];

export default mouldGradingRoutes;
