import { lazy } from 'react';
const hrsalinq = lazy(() => import('./hrsalinq'));

const hrsalinqRoutes = [
  {
    path: '/hrsalinq',
    component: hrsalinq
  }
];

export default hrsalinqRoutes;
