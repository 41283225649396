import { lazy } from 'react';

const mould = lazy(() => import('./mould'));
const mouldNew = lazy(() => import('./mouldNew'));

const mouldRoutes = [
  {
    path: '/mould',
    component: mouldNew
  }
];

export default mouldRoutes;
