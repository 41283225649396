import { lazy } from "react";

const Recutnew = lazy(() => import("./Recutnew"));

const recutnewRoutes = [
  {
    path: "/recutnew",
    component: Recutnew,
  },
];

export default recutnewRoutes;
