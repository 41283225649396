import { lazy } from "react";

const Assignsup = lazy(() => import("./Assignsup"));

const assignSupRoutes = [
  {
    path: "/assignsup",
    component: Assignsup,
  },
];

export default assignSupRoutes;
