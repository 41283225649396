import { lazy } from "react";

const Barcodemuliti = lazy(() => import("./Barcodemuliti"));

const barcodemulitiRoutes = [
  {
    path: "/barcodemuliti",
    component: Barcodemuliti,
  },
];

export default barcodemulitiRoutes;
