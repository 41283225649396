import { lazy } from 'react';

const Productionplanning = lazy(() => import('./Productionplanning'));

const productionplanningRoutes = [
  {
    path: '/productionplanning',
    component: Productionplanning,
  },
];

export default productionplanningRoutes;
