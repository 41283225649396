import { lazy } from "react";

const BatchList = lazy(() => import("./BatchList"));

const batchRoutes = [
  {
    path: "/batch/list",
    component: BatchList,
  },
];
export default batchRoutes;
