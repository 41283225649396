import { lazy } from 'react';
const coporateReports = lazy(() => import('./corporateReport.jsx'));

const corporateReportRoutes = [
  {
    path: '/corporateReports',
    component: coporateReports
  }
];

export default corporateReportRoutes;
