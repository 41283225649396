import { lazy } from "react";

const Orderbook = lazy(() => import("./Orderbook"));

const orderbookRoutes = [
  {
    path: "/orderbook",
    component: Orderbook,
  },
];

export default orderbookRoutes;
