import { lazy } from "react";

const Approverworkflowrla = lazy(() => import("./Approverworkflowrla"));

const approverworkflowrlaRoutes = [
  {
    path: "/approverworkflowrla",
    component: Approverworkflowrla,
  },
];

export default approverworkflowrlaRoutes;
