import { lazy } from 'react';
const ltxcollecaudit = lazy(() => import('./ltxcollecaudit'));

const ltxcollecauditRoutes = [
  {
    path: '/ltxcollecaudit',
    component: ltxcollecaudit
  }
];

export default ltxcollecauditRoutes;
