import { lazy } from "react";

const JobCreation = lazy(() => import("./JobCreation"));

const JobCreationRoutes = [
  {
    path: "/jobCreation",
    component: JobCreation,
  },
];

export default JobCreationRoutes;
