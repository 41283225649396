import { lazy } from "react";

const Approverpo = lazy(() => import("./Approverpo"));

const approverpoRoutes = [
  {
    path: "/approverpo",
    component: Approverpo,
  },
];

export default approverpoRoutes;
