import { lazy } from "react";

const Supchart = lazy(() => import("./Supchart"));

const supchartRoutes = [
  {
    path: "/supchart",
    component: Supchart,
  },
];

export default supchartRoutes;
