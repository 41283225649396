import { lazy } from "react";

const GlSubClasifics = lazy(() => import("./GlSubClasifics"));

const glSubClasificsRoutes = [
  {
    path: "/glSubClasificsRoutes/list",
    component: GlSubClasifics,
  },
];

export default glSubClasificsRoutes;
