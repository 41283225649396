import { lazy } from "react";

const ChartofAccsList = lazy(() => import("./ChartofAccsList"));

const chartofaccsRoutes = [
  {
    path: "/chartofaccs",
    component: ChartofAccsList,
  },
];

export default chartofaccsRoutes;
