import { lazy } from 'react';
const ltxsampleinq = lazy(() => import('./ltxsampleinq'));

const ltxsampleinqRoutes = [
  {
    path: '/ltxsampleinq',
    component: ltxsampleinq
  }
];

export default ltxsampleinqRoutes;
