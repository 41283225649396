import { lazy } from "react";

const ProdPlanning = lazy(() => import("./ProdPlanning"));

const prodPlanningRoutes = [
  {
    path: "/prodplanning/:jobid",
    component: ProdPlanning,
  },
];

export default prodPlanningRoutes;
