import { lazy } from "react";

const GlPrimaryClasification = lazy(() => import("./GlPrimaryClasification"));

const glPrimaryClasificationRoutes = [
  {
    path: "/glPrimaryClasificationRoutes/list",
    component: GlPrimaryClasification,
  },
];

export default glPrimaryClasificationRoutes;
