import { lazy } from "react";

const Wipinquiry = lazy(() => import("./Wipinquiry"));

const wipinquiryRoutes = [
  {
    path: "/wipinquiry",
    component: Wipinquiry,
  },
];

export default wipinquiryRoutes;
