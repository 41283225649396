import { lazy } from "react";

const Grnnopo = lazy(() => import("./Grnnopo"));

const grnnopoRoutes = [
  {
    path: "/grnnopo",
    component: Grnnopo,
  },
];

export default grnnopoRoutes;
