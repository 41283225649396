import { lazy } from "react";

const Tranport = lazy(() => import("./Tranport"));

const tranportRoutes = [
  {
    path: "/tranport",
    component: Tranport,
  },
];

export default tranportRoutes;
