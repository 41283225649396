import { lazy } from "react";

const Approvesample = lazy(() => import("./Approvesample"));

const approvelatexRoutes = [
  {
    path: "/approvesample",
    component: Approvesample,
  },
];

export default approvelatexRoutes;
