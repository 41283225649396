import { lazy } from "react";

const Diesel = lazy(() => import("./Diesel"));

const dieselRoutes = [
  {
    path: "/diesel",
    component: Diesel,
  },
];

export default dieselRoutes;
