import { lazy } from "react";

const Stprodin = lazy(() => import("./Stprodin"));

const stprodinRoutes = [
  {
    path: "/stprodin",
    component: Stprodin,
  },
];

export default stprodinRoutes;
