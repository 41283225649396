import { lazy } from "react";

const Fundtransfer = lazy(() => import("./Fundtransfer"));

const fundtransferRoutes = [
  {
    path: "/fundtransfer",
    component: Fundtransfer,
  },
];

export default fundtransferRoutes;
