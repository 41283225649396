import { lazy } from "react";

const AssignLocationsUser = lazy(() => import("./AssignLocationsUser"));

const assignLocationsUserRoutes = [
  {
    path: "/assignlocationsuser/list",
    component: AssignLocationsUser,
  },
];

export default assignLocationsUserRoutes;
