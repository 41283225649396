import { lazy } from "react";

const Btreconciliation = lazy(() => import("./Btreconciliation"));

const btreconciliationRoutes = [
  {
    path: "/btreconciliation",
    component: Btreconciliation,
  },
];

export default btreconciliationRoutes;
