import { lazy } from 'react';
const dashboardPurch = lazy(() => import('./dashboardPurch'));

const DashboardPurchRoutes = [
  {
    path: '/dashboardPurch',
    component: dashboardPurch
  }
];

export default DashboardPurchRoutes;