import { lazy } from 'react';
const dummyTransferApproval = lazy(() => import('./dummyTransferApproval'));

const dummyTransferApprovalRoutes = [
  {
    path: '/dummytransferapproval',
    component: dummyTransferApproval
  }
];

export default dummyTransferApprovalRoutes;