import { lazy } from "react";

const GrnListing = lazy(() => import("./GrnListing"));

const grnListingRoutes = [
  {
    path: "/grnlisting",
    component: GrnListing,
  },
];

export default grnListingRoutes;
