import { lazy } from "react";

const Ma = lazy(() => import("./Ma"));

const maRoutes = [
  {
    path: "/ma",
    component: Ma,
  },
];

export default maRoutes;
