import { lazy } from "react";

const Ledgeracchistory = lazy(() => import("./Ledgeracchistory"));

const ledgeracchistoryRoutes = [
  {
    path: "/ledgeracchistory",
    component: Ledgeracchistory,
  },
];

export default ledgeracchistoryRoutes;
