import { lazy } from "react";

const AssignSuppliersRoutesList = lazy(() =>
  import("./AssignSuppliersRoutesList")
);

const assignsuppliersRoutes = [
  {
    path: "/assignsuppliers/list",
    component: AssignSuppliersRoutesList,
  },
];
export default assignsuppliersRoutes;
