import { lazy } from "react";

const Materialissuenote = lazy(() => import("./Materialissuenote"));

const materialissuenoteRoutes = [
  {
    path: "/materialissuenote",
    component: Materialissuenote,
  },
];

export default materialissuenoteRoutes;
