import { lazy } from "react";

const Bankrec = lazy(() => import("./Bankrec"));

const bankrecRoutes = [
  {
    path: "/bankrec",
    component: Bankrec,
  },
];

export default bankrecRoutes;
