import { lazy } from 'react';
const mouldTransfer = lazy(() => import('./mouldTransfer'));

const mouldTransferRoutes = [
  {
    path: '/mouldtra',
    component: mouldTransfer
  }
];

export default mouldTransferRoutes;
