import { lazy } from "react";

const Journalentry = lazy(() => import("./Journalentry"));

const journalentryRoutes = [
  {
    path: "/journalentry",
    component: Journalentry,
  },
];

export default journalentryRoutes;
