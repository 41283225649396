import { lazy } from 'react';
const dashboardMar = lazy(() => import('./dashboardMar'));

const dashboardMarRoutes = [
  {
    path: '/dashboardMar',
    component: dashboardMar
  }
];

export default dashboardMarRoutes;
