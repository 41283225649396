import { lazy } from "react";

const Fabricationfinalload = lazy(() => import("./Fabricationfinalload"));

const fabricationfinalloadRoutes = [
  {
    path: "/fabricationfinalload",
    component: Fabricationfinalload,
  },
];

export default fabricationfinalloadRoutes;
