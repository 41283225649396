import { lazy } from "react";

const Pinyear = lazy(() => import("./Pinyear"));

const pinyearRoutes = [
  {
    path: "/pinyear",
    component: Pinyear,
  },
];

export default pinyearRoutes;
