import { lazy } from "react";

const receiptupld = lazy(() => import("./receiptupld"));

const receiptupldRoutes = [
    {
        path: "/receiptupld",
        component: receiptupld,
    },
];

export default receiptupldRoutes;