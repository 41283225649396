import { lazy } from "react";

const Stdtprod = lazy(() => import("./Stdtprod"));

const stdtprodRoutes = [
  {
    path: "/stdtprod",
    component: Stdtprod,
  },
];

export default stdtprodRoutes;
