import { lazy } from 'react';
const ordrec = lazy(() => import('./ord-rec'));

const ordrecRoutes = [
  {
    path: '/sop/ord-rec',
    component: ordrec
  }
];

export default ordrecRoutes;
