import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Dashboard1 = lazy(() => import("./dashboard1/Dashboard1"));
const DashboardBatch = lazy(() => import("./dashboardbatch/DashboardBatch"));

const Finalpaymnetlist = lazy(() => import("./finalpaymnetlist/Finalpaymnetlist"));


const dashboardRoutes = [
  {
    path: "/dashboard/v1/:his?",
    component: Dashboard1,
    auth: authRoles.admin,
  },
  {
    path: "/finalpaymnetlist/:his?",
    component: Finalpaymnetlist,
    auth: authRoles.admin,
  },
  {
    path: "/dashboard/batch",
    component: DashboardBatch,
    auth: authRoles.admin,
  },
];

export default dashboardRoutes;
