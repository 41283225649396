import { lazy } from "react";

const Gdrive = lazy(() => import("./Gdrive"));

const gdriveRoutes = [
  {
    path: "/gdrive",
    component: Gdrive,
  },
];

export default gdriveRoutes;
