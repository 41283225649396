import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Product = lazy(() => import("./Product"));

const productRoutes = [
  {
    path: "/product",
    component: Product,
  },
];

export default productRoutes;
