import { lazy } from "react";

const FinalDrc = lazy(() => import("./FinalDrc"));
const FinalDrcNew = lazy(() => import("./FinalDrcNew"));

const finalDrcRoutes = [
  {
    path: "/finaldrc",
    component: FinalDrcNew,
  },
];

export default finalDrcRoutes;
