import { lazy } from 'react';
const supplierInquiry = lazy(() => import('./supplierInquiry'));

const supplierInquiryRoutes = [
  {
    path: '/supplierinquiry',
    component: supplierInquiry
  }
];

export default supplierInquiryRoutes;
