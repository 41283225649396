/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSmSequence = /* GraphQL */ `
  query GetSmSequence($id: ID!) {
    getSmSequence(id: $id) {
      id
      companyID
      company {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      loccod
      seqid
      incby
      curv
      creaby
      credat
      modiby
      moddat
      descri
      createdAt
      updatedAt
    }
  }
`;
export const listSmSequences = /* GraphQL */ `
  query ListSmSequences(
    $filter: ModelSmSequenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSmSequences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        loccod
        seqid
        incby
        curv
        creaby
        credat
        modiby
        moddat
        descri
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAcPayable = /* GraphQL */ `
  query GetAcPayable($id: ID!) {
    getAcPayable(id: $id) {
      id
      sbucod
      loccod
      linnum
      doccod
      docnum
      sdocco
      sdocnu
      csflag
      cscode
      tradat
      paydat
      amount
      balamt
      creaby
      creadt
      modiby
      modidt
      advamt
      createdAt
      updatedAt
    }
  }
`;
export const listAcPayables = /* GraphQL */ `
  query ListAcPayables(
    $filter: ModelAcPayableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAcPayables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        loccod
        linnum
        doccod
        docnum
        sdocco
        sdocnu
        csflag
        cscode
        tradat
        paydat
        amount
        balamt
        creaby
        creadt
        modiby
        modidt
        advamt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAcppayVous = /* GraphQL */ `
  query GetAcppayVous($id: ID!) {
    getAcppayVous(id: $id) {
      id
      sbucod
      loccod
      battyp
      batcno
      doccod
      docnum
      seqnum
      docdat
      csflag
      cscode
      bacurc
      amount
      curcod
      excrat
      amfrcu
      remark
      remar2
      suinno
      suindt
      paydue
      creaby
      creadt
      modiby
      modidt
      pmtbnk
      chqnum
      chqprt
      name
      invref
      createdAt
      updatedAt
    }
  }
`;
export const listAcppayVous = /* GraphQL */ `
  query ListAcppayVous(
    $filter: ModelAcppayVousFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAcppayVous(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        loccod
        battyp
        batcno
        doccod
        docnum
        seqnum
        docdat
        csflag
        cscode
        bacurc
        amount
        curcod
        excrat
        amfrcu
        remark
        remar2
        suinno
        suindt
        paydue
        creaby
        creadt
        modiby
        modidt
        pmtbnk
        chqnum
        chqprt
        name
        invref
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getParentForm = /* GraphQL */ `
  query GetParentForm($id: ID!) {
    getParentForm(id: $id) {
      id
      parentID
      parentDes
      masterID
      sequence
      level
      icon
      path
      creaby
      modiby
      createdAt
      updatedAt
    }
  }
`;
export const listParentForms = /* GraphQL */ `
  query ListParentForms(
    $filter: ModelParentFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParentForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentID
        parentDes
        masterID
        sequence
        level
        icon
        path
        creaby
        modiby
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      roleID
      roleName
      creaby
      modiby
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleID
        roleName
        creaby
        modiby
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRoleParentForm = /* GraphQL */ `
  query GetRoleParentForm($id: ID!) {
    getRoleParentForm(id: $id) {
      id
      roleID
      role {
        id
        roleID
        roleName
        creaby
        modiby
        createdAt
        updatedAt
      }
      parentFormID
      parentForm {
        id
        parentID
        parentDes
        masterID
        sequence
        level
        icon
        path
        creaby
        modiby
        createdAt
        updatedAt
      }
      creaby
      modiby
      createdAt
      updatedAt
    }
  }
`;
export const listRoleParentForms = /* GraphQL */ `
  query ListRoleParentForms(
    $filter: ModelRoleParentFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleParentForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleID
        role {
          id
          roleID
          roleName
          creaby
          modiby
          createdAt
          updatedAt
        }
        parentFormID
        parentForm {
          id
          parentID
          parentDes
          masterID
          sequence
          level
          icon
          path
          creaby
          modiby
          createdAt
          updatedAt
        }
        creaby
        modiby
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserCompanyRole = /* GraphQL */ `
  query GetUserCompanyRole($id: ID!) {
    getUserCompanyRole(id: $id) {
      id
      roleID
      role {
        id
        roleID
        roleName
        creaby
        modiby
        createdAt
        updatedAt
      }
      userID
      user {
        id
        userId
        title
        userName
        sex
        add1
        add2
        add3
        nic
        email
        mobile
        password
        creaby
        creadt
        modiby
        modidt
        lstlog
        fulacs
        active
        buycod
        plncod
        empnum
        failcn
        usrlck
        cognitoId
        changePwd
        driver
        createdAt
        updatedAt
      }
      sbucod
      companyID
      company {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      creaby
      modiby
      createdAt
      updatedAt
    }
  }
`;
export const listUserCompanyRoles = /* GraphQL */ `
  query ListUserCompanyRoles(
    $filter: ModeluserCompanyRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserCompanyRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        roleID
        role {
          id
          roleID
          roleName
          creaby
          modiby
          createdAt
          updatedAt
        }
        userID
        user {
          id
          userId
          title
          userName
          sex
          add1
          add2
          add3
          nic
          email
          mobile
          password
          creaby
          creadt
          modiby
          modidt
          lstlog
          fulacs
          active
          buycod
          plncod
          empnum
          failcn
          usrlck
          cognitoId
          changePwd
          driver
          createdAt
          updatedAt
        }
        sbucod
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        creaby
        modiby
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userId
      title
      userName
      sex
      add1
      add2
      add3
      nic
      email
      mobile
      password
      creaby
      creadt
      modiby
      modidt
      lstlog
      fulacs
      active
      buycod
      plncod
      empnum
      failcn
      usrlck
      cognitoId
      changePwd
      driver
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        title
        userName
        sex
        add1
        add2
        add3
        nic
        email
        mobile
        password
        creaby
        creadt
        modiby
        modidt
        lstlog
        fulacs
        active
        buycod
        plncod
        empnum
        failcn
        usrlck
        cognitoId
        changePwd
        driver
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getItemMaster = /* GraphQL */ `
  query GetItemMaster($id: ID!) {
    getItemMaster(id: $id) {
      id
      companyID
      company {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      loccod
      plucode
      itemcode
      itmdesc
      eancode
      barcode
      primcls
      subcls
      detcls
      unit
      avgprice
      unitprice
      discper
      wsprice
      wsqty
      wsdiscper
      taxcode1
      itmsize
      itmweight
      itmtype
      itmgroup
      perishflg
      onhandq
      lsttxndate
      lstpodate
      lstgrndate
      minordqty
      maxordqty
      fixedordqty
      reordpnt
      leadtime
      safetytime
      unitisepack
      supplier
      rackpos
      buyer
      planner
      creaby
      creadt
      modiby
      modidt
      costprice
      taxcode2
      taxcode3
      taxcode4
      tax1appto
      tax2appto
      tax3appto
      tax4appto
      allowmodavg
      alllowmodcost
      allowmoddisc
      alllowmodpur
      allowmodwsdisc
      allowmodwsprice
      altitmcod
      glgrup
      matcst
      enecst
      labcst
      fixovh
      varovh
      newcst
      newmco
      neweco
      newlco
      newvco
      lastgrnprice
      lotelg
      blksta
      createdAt
      updatedAt
    }
  }
`;
export const listItemMasters = /* GraphQL */ `
  query ListItemMasters(
    $filter: ModelItemMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemMasters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        loccod
        plucode
        itemcode
        itmdesc
        eancode
        barcode
        primcls
        subcls
        detcls
        unit
        avgprice
        unitprice
        discper
        wsprice
        wsqty
        wsdiscper
        taxcode1
        itmsize
        itmweight
        itmtype
        itmgroup
        perishflg
        onhandq
        lsttxndate
        lstpodate
        lstgrndate
        minordqty
        maxordqty
        fixedordqty
        reordpnt
        leadtime
        safetytime
        unitisepack
        supplier
        rackpos
        buyer
        planner
        creaby
        creadt
        modiby
        modidt
        costprice
        taxcode2
        taxcode3
        taxcode4
        tax1appto
        tax2appto
        tax3appto
        tax4appto
        allowmodavg
        alllowmodcost
        allowmoddisc
        alllowmodpur
        allowmodwsdisc
        allowmodwsprice
        altitmcod
        glgrup
        matcst
        enecst
        labcst
        fixovh
        varovh
        newcst
        newmco
        neweco
        newlco
        newvco
        lastgrnprice
        lotelg
        blksta
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      sbucod
      companyID
      company {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      locCode
      locName
      creaby
      creadt
      modiby
      modidt
      add1
      add2
      add3
      status
      loccat
      createdAt
      updatedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        locCode
        locName
        creaby
        creadt
        modiby
        modidt
        add1
        add2
        add3
        status
        loccat
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserLocation = /* GraphQL */ `
  query GetUserLocation($id: ID!) {
    getUserLocation(id: $id) {
      id
      locationID
      location {
        id
        sbucod
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        locCode
        locName
        creaby
        creadt
        modiby
        modidt
        add1
        add2
        add3
        status
        loccat
        createdAt
        updatedAt
      }
      userID
      user {
        id
        userId
        title
        userName
        sex
        add1
        add2
        add3
        nic
        email
        mobile
        password
        creaby
        creadt
        modiby
        modidt
        lstlog
        fulacs
        active
        buycod
        plncod
        empnum
        failcn
        usrlck
        cognitoId
        changePwd
        driver
        createdAt
        updatedAt
      }
      companyID
      company {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      setDefault
      createdAt
      updatedAt
    }
  }
`;
export const listUserLocations = /* GraphQL */ `
  query ListUserLocations(
    $filter: ModelUserLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        userID
        user {
          id
          userId
          title
          userName
          sex
          add1
          add2
          add3
          nic
          email
          mobile
          password
          creaby
          creadt
          modiby
          modidt
          lstlog
          fulacs
          active
          buycod
          plncod
          empnum
          failcn
          usrlck
          cognitoId
          changePwd
          driver
          createdAt
          updatedAt
        }
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        setDefault
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlPriClasifics = /* GraphQL */ `
  query GetGlPriClasifics($id: ID!) {
    getGlPriClasifics(id: $id) {
      id
      sbucod
      companyID
      company {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      accpri
      prides
      creaby
      creadt
      modiby
      modidt
      createdAt
      updatedAt
    }
  }
`;
export const listGlPriClasifics = /* GraphQL */ `
  query ListGlPriClasifics(
    $filter: ModelGlPriClasificsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlPriClasifics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        accpri
        prides
        creaby
        creadt
        modiby
        modidt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustSup = /* GraphQL */ `
  query GetCustSup($id: ID!) {
    getCustSup(id: $id) {
      id
      companyID
      company {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      custSup
      cscode
      name
      add1
      add2
      add3
      city
      busreg
      nic
      tel
      fax
      mobile
      email
      currcod
      areacod
      payterm
      supgrp
      glgrup
      busigrp
      tax1
      tax2
      tax3
      creaby
      creadt
      modiby
      modidt
      status
      creditLimit
      creditBalance
      pmethd
      bnkgurexpdt
      bnkguramt
      repid
      paynam
      payadd1
      payadd2
      payadd3
      createdAt
      updatedAt
    }
  }
`;
export const listCustSups = /* GraphQL */ `
  query ListCustSups(
    $filter: ModelCustSupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustSups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        custSup
        cscode
        name
        add1
        add2
        add3
        city
        busreg
        nic
        tel
        fax
        mobile
        email
        currcod
        areacod
        payterm
        supgrp
        glgrup
        busigrp
        tax1
        tax2
        tax3
        creaby
        creadt
        modiby
        modidt
        status
        creditLimit
        creditBalance
        pmethd
        bnkgurexpdt
        bnkguramt
        repid
        paynam
        payadd1
        payadd2
        payadd3
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getItemGroup = /* GraphQL */ `
  query GetItemGroup($id: ID!) {
    getItemGroup(id: $id) {
      id
      sbucod
      itmgroup
      groupname
      ordexpdate
      RetPriModAl
      GrnPriModAl
      ordtype
      creaby
      creadt
      modiby
      modidt
      GRNEntryAlWpo
      NoExpDays
      ValOnHandQ
      AllowAvgMod
      AllowCosGMod
      AllowDiscMod
      AllowPuMod
      AllowWsdiscMod
      AllowModwsPrice
      createdAt
      updatedAt
    }
  }
`;
export const listItemGroups = /* GraphQL */ `
  query ListItemGroups(
    $filter: ModelItemGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        itmgroup
        groupname
        ordexpdate
        RetPriModAl
        GrnPriModAl
        ordtype
        creaby
        creadt
        modiby
        modidt
        GRNEntryAlWpo
        NoExpDays
        ValOnHandQ
        AllowAvgMod
        AllowCosGMod
        AllowDiscMod
        AllowPuMod
        AllowWsdiscMod
        AllowModwsPrice
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlCharOfAccs = /* GraphQL */ `
  query GetGlCharOfAccs($id: ID!) {
    getGlCharOfAccs(id: $id) {
      id
      sbucod
      interid
      accpri
      accsub
      accdet
      descri
      contac
      alacid
      curcod
      glgrup
      accblk
      bnkacc
      plbs
      creaby
      creadt
      modiby
      modidt
      loccat
      acct01
      acct02
      acct03
      acct04
      acct05
      acct06
      acct07
      acct08
      acct09
      acct10
      acct11
      acct12
      acct13
      acct14
      acct15
      acct16
      acct17
      acct18
      acct19
      acct20
      createdAt
      updatedAt
    }
  }
`;
export const listGlCharOfAccs = /* GraphQL */ `
  query ListGlCharOfAccs(
    $filter: ModelGlCharOfAccsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlCharOfAccs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        interid
        accpri
        accsub
        accdet
        descri
        contac
        alacid
        curcod
        glgrup
        accblk
        bnkacc
        plbs
        creaby
        creadt
        modiby
        modidt
        loccat
        acct01
        acct02
        acct03
        acct04
        acct05
        acct06
        acct07
        acct08
        acct09
        acct10
        acct11
        acct12
        acct13
        acct14
        acct15
        acct16
        acct17
        acct18
        acct19
        acct20
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlSubClasifics = /* GraphQL */ `
  query GetGlSubClasifics($id: ID!) {
    getGlSubClasifics(id: $id) {
      id
      sbucod
      accpri
      accsub
      subdes
      creaby
      creadt
      modiby
      modidt
      createdAt
      updatedAt
    }
  }
`;
export const listGlSubClasifics = /* GraphQL */ `
  query ListGlSubClasifics(
    $filter: ModelGlSubClasificsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlSubClasifics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        accpri
        accsub
        subdes
        creaby
        creadt
        modiby
        modidt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlDetClasifics = /* GraphQL */ `
  query GetGlDetClasifics($id: ID!) {
    getGlDetClasifics(id: $id) {
      id
      sbucod
      loccod
      accpri
      accsub
      accdet
      detdes
      creaby
      creadt
      modiby
      modidt
      createdAt
      updatedAt
    }
  }
`;
export const listGlDetClasifics = /* GraphQL */ `
  query ListGlDetClasifics(
    $filter: ModelGlDetClasificsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlDetClasifics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        loccod
        accpri
        accsub
        accdet
        detdes
        creaby
        creadt
        modiby
        modidt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlSupDebGroup = /* GraphQL */ `
  query GetGlSupDebGroup($id: ID!) {
    getGlSupDebGroup(id: $id) {
      id
      sbucod
      glgrup
      glgrds
      CustSup
      creaby
      creadt
      modiby
      modidt
      createdAt
      updatedAt
    }
  }
`;
export const listGlSupDebGroups = /* GraphQL */ `
  query ListGlSupDebGroups(
    $filter: ModelGlSupDebGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlSupDebGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        glgrup
        glgrds
        CustSup
        creaby
        creadt
        modiby
        modidt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlFinPeriods = /* GraphQL */ `
  query GetGlFinPeriods($id: ID!) {
    getGlFinPeriods(id: $id) {
      id
      sbucod
      fnyear
      period
      stdate
      endate
      acrsta
      apysta
      glpsta
      creaby
      creadt
      modiby
      modidt
      calmon
      monnam
      prdyer
      invsta
      depstat
      currat
      createdAt
      updatedAt
    }
  }
`;
export const listGlFinPeriods = /* GraphQL */ `
  query ListGlFinPeriods(
    $filter: ModelGlFinPeriodsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlFinPeriods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        fnyear
        period
        stdate
        endate
        acrsta
        apysta
        glpsta
        creaby
        creadt
        modiby
        modidt
        calmon
        monnam
        prdyer
        invsta
        depstat
        currat
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlMasAccBals = /* GraphQL */ `
  query GetGlMasAccBals($id: ID!) {
    getGlMasAccBals(id: $id) {
      id
      sbucod
      loccod
      interid
      ledtyp
      finmon
      finyer
      curcod
      amtcrd
      amtdbr
      creaby
      creadt
      modiby
      modidt
      dimm01
      dimm02
      dimm03
      dimm04
      dimm05
      reacod
      amcrfc
      amdrfc
      dimm06
      dimm07
      dimm08
      dimm09
      dimm10
      createdAt
      updatedAt
    }
  }
`;
export const listGlMasAccBals = /* GraphQL */ `
  query ListGlMasAccBals(
    $filter: ModelGlMasAccBalsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlMasAccBals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        loccod
        interid
        ledtyp
        finmon
        finyer
        curcod
        amtcrd
        amtdbr
        creaby
        creadt
        modiby
        modidt
        dimm01
        dimm02
        dimm03
        dimm04
        dimm05
        reacod
        amcrfc
        amdrfc
        dimm06
        dimm07
        dimm08
        dimm09
        dimm10
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlTran = /* GraphQL */ `
  query GetGlTran($id: ID!) {
    getGlTran(id: $id) {
      id
      sbucod
      loccod
      battyp
      batcno
      doccod
      docnum
      linnum
      seqnum
      trandt
      interid
      dimm01
      dimm02
      dimm03
      dimm04
      dimm05
      amount
      drcrty
      curcod
      excrat
      amtfcu
      remark
      modtyp
      creaby
      creadt
      modiby
      modidt
      bnkrec
      unofms
      untqty
      jcat01
      jcat02
      jcat03
      jcat04
      jcat05
      reacod
      reftxt
      lkrrat
      postdt
      astcod
      period
      filept
      createdAt
      updatedAt
    }
  }
`;
export const listGlTrans = /* GraphQL */ `
  query ListGlTrans(
    $filter: ModelGlTranFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlTrans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        loccod
        battyp
        batcno
        doccod
        docnum
        linnum
        seqnum
        trandt
        interid
        dimm01
        dimm02
        dimm03
        dimm04
        dimm05
        amount
        drcrty
        curcod
        excrat
        amtfcu
        remark
        modtyp
        creaby
        creadt
        modiby
        modidt
        bnkrec
        unofms
        untqty
        jcat01
        jcat02
        jcat03
        jcat04
        jcat05
        reacod
        reftxt
        lkrrat
        postdt
        astcod
        period
        filept
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlTranTemp = /* GraphQL */ `
  query GetGlTranTemp($id: ID!) {
    getGlTranTemp(id: $id) {
      id
      sbucod
      loccod
      battyp
      batcno
      doccod
      docnum
      linnum
      seqnum
      trandt
      interid
      dimm01
      dimm02
      dimm03
      dimm04
      dimm05
      amount
      drcrty
      curcod
      excrat
      amtfcu
      remark
      modtyp
      creaby
      creadt
      modiby
      modidt
      bnkrec
      unofms
      untqty
      jcat01
      jcat02
      jcat03
      jcat04
      jcat05
      reacod
      reftxt
      lkrrat
      postdt
      astcod
      period
      filept
      createdAt
      updatedAt
    }
  }
`;
export const listGlTranTemps = /* GraphQL */ `
  query ListGlTranTemps(
    $filter: ModelGlTranTempFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlTranTemps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        loccod
        battyp
        batcno
        doccod
        docnum
        linnum
        seqnum
        trandt
        interid
        dimm01
        dimm02
        dimm03
        dimm04
        dimm05
        amount
        drcrty
        curcod
        excrat
        amtfcu
        remark
        modtyp
        creaby
        creadt
        modiby
        modidt
        bnkrec
        unofms
        untqty
        jcat01
        jcat02
        jcat03
        jcat04
        jcat05
        reacod
        reftxt
        lkrrat
        postdt
        astcod
        period
        filept
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlModJournal = /* GraphQL */ `
  query GetGlModJournal($id: ID!) {
    getGlModJournal(id: $id) {
      id
      sbucod
      loccod
      modcod
      moddes
      seqnum
      interid
      dimm01
      dimm02
      dimm03
      dimm04
      dimm05
      amount
      drcrty
      curcod
      excrat
      amtfcu
      remark
      modtyp
      creaby
      creadt
      modiby
      modidt
      expdat
      unofms
      untqty
      jcat01
      jcat02
      jcat03
      jcat04
      jcat05
      lstpdt
      reftxt
      createdAt
      updatedAt
    }
  }
`;
export const listGlModJournals = /* GraphQL */ `
  query ListGlModJournals(
    $filter: ModelGlModJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlModJournals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        loccod
        modcod
        moddes
        seqnum
        interid
        dimm01
        dimm02
        dimm03
        dimm04
        dimm05
        amount
        drcrty
        curcod
        excrat
        amtfcu
        remark
        modtyp
        creaby
        creadt
        modiby
        modidt
        expdat
        unofms
        untqty
        jcat01
        jcat02
        jcat03
        jcat04
        jcat05
        lstpdt
        reftxt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlTranTempApp = /* GraphQL */ `
  query GetGlTranTempApp($id: ID!) {
    getGlTranTempApp(id: $id) {
      id
      sbucod
      loccod
      battyp
      batcno
      doccod
      docnum
      linnum
      trandt
      interid
      dimm01
      dimm02
      dimm03
      dimm04
      dimm05
      amount
      drcrty
      curcod
      excrat
      amtfcu
      remark
      modtyp
      creaby
      creadt
      modiby
      modidt
      bnkrec
      unofms
      untqty
      jcat01
      jcat02
      jcat03
      jcat04
      jcat05
      reacod
      reftxt
      lkrrat
      postdt
      status
      period
      filept
      createdAt
      updatedAt
    }
  }
`;
export const listGlTranTempApps = /* GraphQL */ `
  query ListGlTranTempApps(
    $filter: ModelGlTranTempAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlTranTempApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        loccod
        battyp
        batcno
        doccod
        docnum
        linnum
        trandt
        interid
        dimm01
        dimm02
        dimm03
        dimm04
        dimm05
        amount
        drcrty
        curcod
        excrat
        amtfcu
        remark
        modtyp
        creaby
        creadt
        modiby
        modidt
        bnkrec
        unofms
        untqty
        jcat01
        jcat02
        jcat03
        jcat04
        jcat05
        reacod
        reftxt
        lkrrat
        postdt
        status
        period
        filept
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTaxRates = /* GraphQL */ `
  query GetTaxRates($id: ID!) {
    getTaxRates(id: $id) {
      id
      sbucod
      taxcod
      taxrate
      creaby
      creadt
      modiby
      modidt
      taxexp
      createdAt
      updatedAt
    }
  }
`;
export const listTaxRates = /* GraphQL */ `
  query ListTaxRates(
    $filter: ModelTaxRatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaxRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        taxcod
        taxrate
        creaby
        creadt
        modiby
        modidt
        taxexp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      sbucod
      name
      add1
      add2
      add3
      city
      mobile
      fax
      tax1
      tax2
      tax3
      email
      phone
      contactPerson
      creaby
      creadt
      modiby
      modidt
      image
      createdAt
      updatedAt
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAdmin = /* GraphQL */ `
  query GetAdmin($id: ID!) {
    getAdmin(id: $id) {
      id
      cognitoId
      name
      email
      phone
      adminAddress1
      adminAddress2
      adminAddress3
      adminNic
      createdAt
      updatedAt
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoId
        name
        email
        phone
        adminAddress1
        adminAddress2
        adminAddress3
        adminNic
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyAdmin = /* GraphQL */ `
  query GetCompanyAdmin($id: ID!) {
    getCompanyAdmin(id: $id) {
      id
      adminId
      companyId
      company {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyAdmins = /* GraphQL */ `
  query ListCompanyAdmins(
    $filter: ModelCompanyAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        adminId
        companyId
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSupplierAdmin = /* GraphQL */ `
  query GetSupplierAdmin($id: ID!) {
    getSupplierAdmin(id: $id) {
      id
      supplierID
      adminID
      createdAt
      updatedAt
    }
  }
`;
export const listSupplierAdmins = /* GraphQL */ `
  query ListSupplierAdmins(
    $filter: ModelSupplierAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupplierAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        supplierID
        adminID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRoute = /* GraphQL */ `
  query GetRoute($id: ID!) {
    getRoute(id: $id) {
      id
      companyID
      company {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      routeCode
      routeName
      locationID
      location {
        id
        sbucod
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        locCode
        locName
        creaby
        creadt
        modiby
        modidt
        add1
        add2
        add3
        status
        loccat
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRoutes = /* GraphQL */ `
  query ListRoutes(
    $filter: ModelRouteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoutes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        routeCode
        routeName
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRouteSupplier = /* GraphQL */ `
  query GetRouteSupplier($id: ID!) {
    getRouteSupplier(id: $id) {
      id
      companyID
      company {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      routeId
      route {
        id
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        routeCode
        routeName
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      custSupID
      custSup {
        id
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        custSup
        cscode
        name
        add1
        add2
        add3
        city
        busreg
        nic
        tel
        fax
        mobile
        email
        currcod
        areacod
        payterm
        supgrp
        glgrup
        busigrp
        tax1
        tax2
        tax3
        creaby
        creadt
        modiby
        modidt
        status
        creditLimit
        creditBalance
        pmethd
        bnkgurexpdt
        bnkguramt
        repid
        paynam
        payadd1
        payadd2
        payadd3
        createdAt
        updatedAt
      }
      locationID
      location {
        id
        sbucod
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        locCode
        locName
        creaby
        creadt
        modiby
        modidt
        add1
        add2
        add3
        status
        loccat
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRouteSuppliers = /* GraphQL */ `
  query ListRouteSuppliers(
    $filter: ModelRouteSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRouteSuppliers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        routeId
        route {
          id
          companyID
          routeCode
          routeName
          locationID
          createdAt
          updatedAt
        }
        custSupID
        custSup {
          id
          companyID
          custSup
          cscode
          name
          add1
          add2
          add3
          city
          busreg
          nic
          tel
          fax
          mobile
          email
          currcod
          areacod
          payterm
          supgrp
          glgrup
          busigrp
          tax1
          tax2
          tax3
          creaby
          creadt
          modiby
          modidt
          status
          creditLimit
          creditBalance
          pmethd
          bnkgurexpdt
          bnkguramt
          repid
          paynam
          payadd1
          payadd2
          payadd3
          createdAt
          updatedAt
        }
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSummary = /* GraphQL */ `
  query GetSummary($id: ID!) {
    getSummary(id: $id) {
      id
      companyID
      company {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      batchID
      batch {
        id
        batchCode
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        routeId
        route {
          id
          companyID
          routeCode
          routeName
          locationID
          createdAt
          updatedAt
        }
        userID
        user {
          id
          userId
          title
          userName
          sex
          add1
          add2
          add3
          nic
          email
          mobile
          password
          creaby
          creadt
          modiby
          modidt
          lstlog
          fulacs
          active
          buycod
          plncod
          empnum
          failcn
          usrlck
          cognitoId
          changePwd
          driver
          createdAt
          updatedAt
        }
        batchVehicleNo
        date
        status
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      fldqty
      fldltr
      accqty
      accltr
      TSC
      NRC
      VFA
      DRC
      remark
      status
      locationID
      location {
        id
        sbucod
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        locCode
        locName
        creaby
        creadt
        modiby
        modidt
        add1
        add2
        add3
        status
        loccat
        createdAt
        updatedAt
      }
      container
      sampleDRC
      finalDRC
      samdryweight
      findryweight
      dryweightdif
      invqty
      createdAt
      updatedAt
    }
  }
`;
export const listSummaries = /* GraphQL */ `
  query ListSummaries(
    $filter: ModelSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSummaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        batchID
        batch {
          id
          batchCode
          companyID
          routeId
          userID
          batchVehicleNo
          date
          status
          locationID
          createdAt
          updatedAt
        }
        fldqty
        fldltr
        accqty
        accltr
        TSC
        NRC
        VFA
        DRC
        remark
        status
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        container
        sampleDRC
        finalDRC
        samdryweight
        findryweight
        dryweightdif
        invqty
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBatch = /* GraphQL */ `
  query GetBatch($id: ID!) {
    getBatch(id: $id) {
      id
      batchCode
      companyID
      company {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      routeId
      route {
        id
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        routeCode
        routeName
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      userID
      user {
        id
        userId
        title
        userName
        sex
        add1
        add2
        add3
        nic
        email
        mobile
        password
        creaby
        creadt
        modiby
        modidt
        lstlog
        fulacs
        active
        buycod
        plncod
        empnum
        failcn
        usrlck
        cognitoId
        changePwd
        driver
        createdAt
        updatedAt
      }
      batchVehicleNo
      date
      status
      locationID
      location {
        id
        sbucod
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        locCode
        locName
        creaby
        creadt
        modiby
        modidt
        add1
        add2
        add3
        status
        loccat
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBatches = /* GraphQL */ `
  query ListBatches(
    $filter: ModelBatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        batchCode
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        routeId
        route {
          id
          companyID
          routeCode
          routeName
          locationID
          createdAt
          updatedAt
        }
        userID
        user {
          id
          userId
          title
          userName
          sex
          add1
          add2
          add3
          nic
          email
          mobile
          password
          creaby
          creadt
          modiby
          modidt
          lstlog
          fulacs
          active
          buycod
          plncod
          empnum
          failcn
          usrlck
          cognitoId
          changePwd
          driver
          createdAt
          updatedAt
        }
        batchVehicleNo
        date
        status
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBatchItem = /* GraphQL */ `
  query GetBatchItem($id: ID!) {
    getBatchItem(id: $id) {
      id
      latexCode
      type
      companyID
      company {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      batchID
      batch {
        id
        batchCode
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        routeId
        route {
          id
          companyID
          routeCode
          routeName
          locationID
          createdAt
          updatedAt
        }
        userID
        user {
          id
          userId
          title
          userName
          sex
          add1
          add2
          add3
          nic
          email
          mobile
          password
          creaby
          creadt
          modiby
          modidt
          lstlog
          fulacs
          active
          buycod
          plncod
          empnum
          failcn
          usrlck
          cognitoId
          changePwd
          driver
          createdAt
          updatedAt
        }
        batchVehicleNo
        date
        status
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      custSupID
      custSup {
        id
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        custSup
        cscode
        name
        add1
        add2
        add3
        city
        busreg
        nic
        tel
        fax
        mobile
        email
        currcod
        areacod
        payterm
        supgrp
        glgrup
        busigrp
        tax1
        tax2
        tax3
        creaby
        creadt
        modiby
        modidt
        status
        creditLimit
        creditBalance
        pmethd
        bnkgurexpdt
        bnkguramt
        repid
        paynam
        payadd1
        payadd2
        payadd3
        createdAt
        updatedAt
      }
      userID
      user {
        id
        userId
        title
        userName
        sex
        add1
        add2
        add3
        nic
        email
        mobile
        password
        creaby
        creadt
        modiby
        modidt
        lstlog
        fulacs
        active
        buycod
        plncod
        empnum
        failcn
        usrlck
        cognitoId
        changePwd
        driver
        createdAt
        updatedAt
      }
      status
      findrc
      samdrc
      locationID
      location {
        id
        sbucod
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        locCode
        locName
        creaby
        creadt
        modiby
        modidt
        add1
        add2
        add3
        status
        loccat
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBatchItems = /* GraphQL */ `
  query ListBatchItems(
    $filter: ModelBatchItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBatchItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        latexCode
        type
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        batchID
        batch {
          id
          batchCode
          companyID
          routeId
          userID
          batchVehicleNo
          date
          status
          locationID
          createdAt
          updatedAt
        }
        custSupID
        custSup {
          id
          companyID
          custSup
          cscode
          name
          add1
          add2
          add3
          city
          busreg
          nic
          tel
          fax
          mobile
          email
          currcod
          areacod
          payterm
          supgrp
          glgrup
          busigrp
          tax1
          tax2
          tax3
          creaby
          creadt
          modiby
          modidt
          status
          creditLimit
          creditBalance
          pmethd
          bnkgurexpdt
          bnkguramt
          repid
          paynam
          payadd1
          payadd2
          payadd3
          createdAt
          updatedAt
        }
        userID
        user {
          id
          userId
          title
          userName
          sex
          add1
          add2
          add3
          nic
          email
          mobile
          password
          creaby
          creadt
          modiby
          modidt
          lstlog
          fulacs
          active
          buycod
          plncod
          empnum
          failcn
          usrlck
          cognitoId
          changePwd
          driver
          createdAt
          updatedAt
        }
        status
        findrc
        samdrc
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBatchItemPicItem = /* GraphQL */ `
  query GetBatchItemPicItem($id: ID!) {
    getBatchItemPicItem(id: $id) {
      id
      companyID
      company {
        id
        sbucod
        name
        add1
        add2
        add3
        city
        mobile
        fax
        tax1
        tax2
        tax3
        email
        phone
        contactPerson
        creaby
        creadt
        modiby
        modidt
        image
        createdAt
        updatedAt
      }
      type
      picCode
      batchID
      batch {
        id
        batchCode
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        routeId
        route {
          id
          companyID
          routeCode
          routeName
          locationID
          createdAt
          updatedAt
        }
        userID
        user {
          id
          userId
          title
          userName
          sex
          add1
          add2
          add3
          nic
          email
          mobile
          password
          creaby
          creadt
          modiby
          modidt
          lstlog
          fulacs
          active
          buycod
          plncod
          empnum
          failcn
          usrlck
          cognitoId
          changePwd
          driver
          createdAt
          updatedAt
        }
        batchVehicleNo
        date
        status
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      batchItemID
      batchitem {
        id
        latexCode
        type
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        batchID
        batch {
          id
          batchCode
          companyID
          routeId
          userID
          batchVehicleNo
          date
          status
          locationID
          createdAt
          updatedAt
        }
        custSupID
        custSup {
          id
          companyID
          custSup
          cscode
          name
          add1
          add2
          add3
          city
          busreg
          nic
          tel
          fax
          mobile
          email
          currcod
          areacod
          payterm
          supgrp
          glgrup
          busigrp
          tax1
          tax2
          tax3
          creaby
          creadt
          modiby
          modidt
          status
          creditLimit
          creditBalance
          pmethd
          bnkgurexpdt
          bnkguramt
          repid
          paynam
          payadd1
          payadd2
          payadd3
          createdAt
          updatedAt
        }
        userID
        user {
          id
          userId
          title
          userName
          sex
          add1
          add2
          add3
          nic
          email
          mobile
          password
          creaby
          creadt
          modiby
          modidt
          lstlog
          fulacs
          active
          buycod
          plncod
          empnum
          failcn
          usrlck
          cognitoId
          changePwd
          driver
          createdAt
          updatedAt
        }
        status
        findrc
        samdrc
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      itemMasterID
      itemMaster {
        id
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        loccod
        plucode
        itemcode
        itmdesc
        eancode
        barcode
        primcls
        subcls
        detcls
        unit
        avgprice
        unitprice
        discper
        wsprice
        wsqty
        wsdiscper
        taxcode1
        itmsize
        itmweight
        itmtype
        itmgroup
        perishflg
        onhandq
        lsttxndate
        lstpodate
        lstgrndate
        minordqty
        maxordqty
        fixedordqty
        reordpnt
        leadtime
        safetytime
        unitisepack
        supplier
        rackpos
        buyer
        planner
        creaby
        creadt
        modiby
        modidt
        costprice
        taxcode2
        taxcode3
        taxcode4
        tax1appto
        tax2appto
        tax3appto
        tax4appto
        allowmodavg
        alllowmodcost
        allowmoddisc
        alllowmodpur
        allowmodwsdisc
        allowmodwsprice
        altitmcod
        glgrup
        matcst
        enecst
        labcst
        fixovh
        varovh
        newcst
        newmco
        neweco
        newlco
        newvco
        lastgrnprice
        lotelg
        blksta
        createdAt
        updatedAt
      }
      liters
      kgs
      metrolac
      temp
      nh3
      tz
      remarks
      samdrc
      findrc
      locationID
      location {
        id
        sbucod
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        locCode
        locName
        creaby
        creadt
        modiby
        modidt
        add1
        add2
        add3
        status
        loccat
        createdAt
        updatedAt
      }
      container
      sampleDRC
      finalDRC
      createdAt
      updatedAt
    }
  }
`;
export const listBatchItemPicItems = /* GraphQL */ `
  query ListBatchItemPicItems(
    $filter: ModelBatchItemPicItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBatchItemPicItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        type
        picCode
        batchID
        batch {
          id
          batchCode
          companyID
          routeId
          userID
          batchVehicleNo
          date
          status
          locationID
          createdAt
          updatedAt
        }
        batchItemID
        batchitem {
          id
          latexCode
          type
          companyID
          batchID
          custSupID
          userID
          status
          findrc
          samdrc
          locationID
          createdAt
          updatedAt
        }
        itemMasterID
        itemMaster {
          id
          companyID
          loccod
          plucode
          itemcode
          itmdesc
          eancode
          barcode
          primcls
          subcls
          detcls
          unit
          avgprice
          unitprice
          discper
          wsprice
          wsqty
          wsdiscper
          taxcode1
          itmsize
          itmweight
          itmtype
          itmgroup
          perishflg
          onhandq
          lsttxndate
          lstpodate
          lstgrndate
          minordqty
          maxordqty
          fixedordqty
          reordpnt
          leadtime
          safetytime
          unitisepack
          supplier
          rackpos
          buyer
          planner
          creaby
          creadt
          modiby
          modidt
          costprice
          taxcode2
          taxcode3
          taxcode4
          tax1appto
          tax2appto
          tax3appto
          tax4appto
          allowmodavg
          alllowmodcost
          allowmoddisc
          alllowmodpur
          allowmodwsdisc
          allowmodwsprice
          altitmcod
          glgrup
          matcst
          enecst
          labcst
          fixovh
          varovh
          newcst
          newmco
          neweco
          newlco
          newvco
          lastgrnprice
          lotelg
          blksta
          createdAt
          updatedAt
        }
        liters
        kgs
        metrolac
        temp
        nh3
        tz
        remarks
        samdrc
        findrc
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        container
        sampleDRC
        finalDRC
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const batchitemsByDate = /* GraphQL */ `
  query BatchitemsByDate(
    $type: String
    $latexCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBatchItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    batchitemsByDate(
      type: $type
      latexCode: $latexCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        latexCode
        type
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        batchID
        batch {
          id
          batchCode
          companyID
          routeId
          userID
          batchVehicleNo
          date
          status
          locationID
          createdAt
          updatedAt
        }
        custSupID
        custSup {
          id
          companyID
          custSup
          cscode
          name
          add1
          add2
          add3
          city
          busreg
          nic
          tel
          fax
          mobile
          email
          currcod
          areacod
          payterm
          supgrp
          glgrup
          busigrp
          tax1
          tax2
          tax3
          creaby
          creadt
          modiby
          modidt
          status
          creditLimit
          creditBalance
          pmethd
          bnkgurexpdt
          bnkguramt
          repid
          paynam
          payadd1
          payadd2
          payadd3
          createdAt
          updatedAt
        }
        userID
        user {
          id
          userId
          title
          userName
          sex
          add1
          add2
          add3
          nic
          email
          mobile
          password
          creaby
          creadt
          modiby
          modidt
          lstlog
          fulacs
          active
          buycod
          plncod
          empnum
          failcn
          usrlck
          cognitoId
          changePwd
          driver
          createdAt
          updatedAt
        }
        status
        findrc
        samdrc
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const batchItemPicItemsByDate = /* GraphQL */ `
  query BatchItemPicItemsByDate(
    $type: String
    $picCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBatchItemPicItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    batchItemPicItemsByDate(
      type: $type
      picCode: $picCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyID
        company {
          id
          sbucod
          name
          add1
          add2
          add3
          city
          mobile
          fax
          tax1
          tax2
          tax3
          email
          phone
          contactPerson
          creaby
          creadt
          modiby
          modidt
          image
          createdAt
          updatedAt
        }
        type
        picCode
        batchID
        batch {
          id
          batchCode
          companyID
          routeId
          userID
          batchVehicleNo
          date
          status
          locationID
          createdAt
          updatedAt
        }
        batchItemID
        batchitem {
          id
          latexCode
          type
          companyID
          batchID
          custSupID
          userID
          status
          findrc
          samdrc
          locationID
          createdAt
          updatedAt
        }
        itemMasterID
        itemMaster {
          id
          companyID
          loccod
          plucode
          itemcode
          itmdesc
          eancode
          barcode
          primcls
          subcls
          detcls
          unit
          avgprice
          unitprice
          discper
          wsprice
          wsqty
          wsdiscper
          taxcode1
          itmsize
          itmweight
          itmtype
          itmgroup
          perishflg
          onhandq
          lsttxndate
          lstpodate
          lstgrndate
          minordqty
          maxordqty
          fixedordqty
          reordpnt
          leadtime
          safetytime
          unitisepack
          supplier
          rackpos
          buyer
          planner
          creaby
          creadt
          modiby
          modidt
          costprice
          taxcode2
          taxcode3
          taxcode4
          tax1appto
          tax2appto
          tax3appto
          tax4appto
          allowmodavg
          alllowmodcost
          allowmoddisc
          alllowmodpur
          allowmodwsdisc
          allowmodwsprice
          altitmcod
          glgrup
          matcst
          enecst
          labcst
          fixovh
          varovh
          newcst
          newmco
          neweco
          newlco
          newvco
          lastgrnprice
          lotelg
          blksta
          createdAt
          updatedAt
        }
        liters
        kgs
        metrolac
        temp
        nh3
        tz
        remarks
        samdrc
        findrc
        locationID
        location {
          id
          sbucod
          companyID
          locCode
          locName
          creaby
          creadt
          modiby
          modidt
          add1
          add2
          add3
          status
          loccat
          createdAt
          updatedAt
        }
        container
        sampleDRC
        finalDRC
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
