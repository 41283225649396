import { lazy } from 'react';
const dashboardPlanning = lazy(() => import('./dashboardPlanning'));

const dashboardPlanningRoutes = [
  {
    path: '/dashboardPlanning',
    component: dashboardPlanning
  }
];

export default dashboardPlanningRoutes;
