import { lazy } from 'react';
const dashLab = lazy(() => import('./dashLab'));

const dashLabRoutes = [
  {
    path: '/dashLab',
    component: dashLab
  }
];

export default dashLabRoutes;
