import { lazy } from "react";

const Filling = lazy(() => import("./Filling"));

const fillingRoutes = [
  {
    path: "/filling",
    component: Filling,
  },
];

export default fillingRoutes;
