import { lazy } from "react";

const ApproverWorkFlow = lazy(() => import("./ApproverWorkFlow"));

const approverWorkFlowRoutes = [
  {
    path: "/approverworkflow",
    component: ApproverWorkFlow,
  },
];

export default approverWorkFlowRoutes;
