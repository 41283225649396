import { lazy } from "react";

const Vesselreport = lazy(() => import("./Vesselreport"));

const vesselreportRoutes = [
  {
    path: "/vesselreport",
    component: Vesselreport,
  },
];

export default vesselreportRoutes;
