import { lazy } from "react";

const Creditors = lazy(() => import("./Creditors"));

const creditorsRoutes = [
  {
    path: "/creditors",
    component: Creditors,
  },
];

export default creditorsRoutes;
