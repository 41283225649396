import { lazy } from "react";

const LatexrateList = lazy(() =>
  import("./LatexrateList")
);

const latexrateListRoutes = [
  {
    path: "/latexrate/list",
    component: LatexrateList,
  },
];
export default latexrateListRoutes;
