import { lazy } from "react";

const Bttankchart = lazy(() => import("./Bttankchart"));

const bttankchartRoutes = [
  {
    path: "/bttankchart",
    component: Bttankchart,
  },
];

export default bttankchartRoutes;
