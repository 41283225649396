import { lazy } from 'react';
const priceUpdateBatchItemPicItemId = lazy(() => import('./priceUpdateBatchItemPicItemId'));

const priceUpdateBatchItemPicItemIdRoutes = [
  {
    path: '/prcupdtbchitm',
    component: priceUpdateBatchItemPicItemId
  }
];

export default priceUpdateBatchItemPicItemIdRoutes;
