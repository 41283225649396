import { lazy } from "react";

const Fabricationwipmonitoring = lazy(() => import("./Fabricationwipmonitoring"));

const fabricationwipmonitoringRoutes = [
  {
    path: "/fabricationwipmonitoring",
    component: Fabricationwipmonitoring,
  },
];

export default fabricationwipmonitoringRoutes;
