import { lazy } from "react";
const latexSupplierPaymentInquiry = lazy(() =>
  import("./latexSupplierPaymentInquiry")
);

const latexSupplierPaymentInquiryRoutes = [
    {
        path: "/ltxsupayinq",
        component: latexSupplierPaymentInquiry
    }
];

export default latexSupplierPaymentInquiryRoutes;