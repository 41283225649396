import { lazy } from "react";

const Prodplanninglast = lazy(() => import("./Prodplanninglast"));

const prodplanninglastRoutes = [
  {
    path: "/prodplanninglast",
    component: Prodplanninglast,
  },
];

export default prodplanninglastRoutes;
