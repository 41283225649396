import { lazy } from "react";

const Sms = lazy(() => import("./Sms"));

const smsRoutes = [
  {
    path: "/sms/:his?",
    component: Sms,
  },
];

export default smsRoutes;
