import { lazy } from "react";

const Localinvoice = lazy(() => import("./Localinvoice"));

const localinvoiceRoutes = [
  {
    path: "/localinvoice",
    component: Localinvoice,
  },
];

export default localinvoiceRoutes;
