import { lazy } from "react";

const Grnliability = lazy(() => import("./Grnliability"));

const grnliabilitytRoutes = [
  {
    path: "/grnliability",
    component: Grnliability,
  },
];

export default grnliabilitytRoutes;
