import { lazy } from 'react';
const orderfginq = lazy(() => import('./orderfginq'));

const orderfginqRoutes = [
  {
    path: '/orderfginq',
    component: orderfginq
  }
];

export default orderfginqRoutes;