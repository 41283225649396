import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Users = lazy(() => import("./Users"));

const usersRoutes = [
  {
    path: "/users/list",
    component: Users,
  },
];

export default usersRoutes;
