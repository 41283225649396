import { lazy } from "react";

const Dryerinput = lazy(() => import("./Dryerinput"));

const dryerinputRoutes = [
  {
    path: "/dryerinput",
    component: Dryerinput,
  },
];

export default dryerinputRoutes;
