import { lazy } from "react";

const Purchaseorder = lazy(() => import("./Purchaseorder"));

const purchaseorderRoutes = [
  {
    path: "/purchaseorder",
    component: Purchaseorder,
  },
];

export default purchaseorderRoutes;
