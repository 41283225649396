import { lazy } from "react";

const ProdPlanningBefore = lazy(() => import("./ProdPlanningBefore"));

const prodPlanningBeforeRoutes = [
  {
    path: "/prodplanningbefore/:jobid",
    component: ProdPlanningBefore,
  },
];

export default prodPlanningBeforeRoutes;
