import { lazy } from "react";

const Wipskimin = lazy(() => import("./Wipskimin"));

const wipskiminRoutes = [
  {
    path: "/wipskimin",
    component: Wipskimin,
  },
];

export default wipskiminRoutes;
