import { lazy } from "react";

const Orderbooknew = lazy(() => import("./Orderbooknew"));

const orderbooknewRoutes = [
  {
    path: "/orderbooknew",
    component: Orderbooknew,
  },
];

export default orderbooknewRoutes;
