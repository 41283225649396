import { lazy } from 'react';
const purchasingprocedures = lazy(() => import('./purchasing-procedures'));

const purchasingproceduresRoutes = [
  {
    path: '/sop/purchasing-procedures',
    component: purchasingprocedures
  }
];

export default purchasingproceduresRoutes;
