import { lazy } from "react";

const AssignCompanyList = lazy(() => import("./AssignCompanyList"));

const assigncompanyRoutes = [
  {
    path: "/assigncompany/list",
    component: AssignCompanyList,
  },
];
export default assigncompanyRoutes;
