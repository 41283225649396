import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CompanyAdminList = lazy(() => import("./CompanyAdminList"));

const companyadminRoutes = [
  {
    path: "/companyadmin/list",
    component: CompanyAdminList,
    auth: authRoles.rootadmin,
  },
];

export default companyadminRoutes;
