import { lazy } from "react";

const ProcessPaymnet = lazy(() => import("./ProcessPaymnet"));

const processPaymnetRoutes = [
  {
    path: "/processpaymnet/:batchId",
    component: ProcessPaymnet,
  },
];

export default processPaymnetRoutes;
