import { lazy } from "react";

const Gtnolot = lazy(() => import("./Gtnolot"));

const gtnolotRoutes = [
  {
    path: "/gtnolot",
    component: Gtnolot,
  },
];

export default gtnolotRoutes;
