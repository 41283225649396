import { lazy } from 'react';

const mouldRepair = lazy(() => import('./mouldRepair'));
const mouldRepairNew = lazy(() => import('./mouldRepairNew'));

const mouldRepairRoutes = [
  {
    path: '/mouldrepair',
    component: mouldRepairNew
  }
];

export default mouldRepairRoutes;
