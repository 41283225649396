import { lazy } from "react";

const Fgbarcodevh = lazy(() => import("./Fgbarcodevh"));

const fgbarcodevhRoutes = [
  {
    path: "/fgbarcodevh",
    component: Fgbarcodevh,
  },
];

export default fgbarcodevhRoutes;
