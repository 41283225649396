import { lazy } from "react";

const Cnote = lazy(() => import("./Cnote"));

const cnoteRoutes = [
  {
    path: "/cnote",
    component: Cnote,
  },
];

export default cnoteRoutes;
