import { lazy } from "react";

const Matresscover = lazy(() => import("./Matresscover"));

const matresscoverRoutes = [
  {
    path: "/matresscover",
    component: Matresscover,
  },
];

export default matresscoverRoutes;
