import { lazy } from "react";

const Latexgrn = lazy(() => import("./Latexgrn"));

const latexgrnRoutes = [
  {
    path: "/latexgrnn",
    component: Latexgrn,
  },
];

export default latexgrnRoutes;
