import { lazy } from "react";

const Xlsx = lazy(() => import("./Xlsx"));

const xlsxRoutes = [
  {
    path: "/xlsx",
    component: Xlsx,
  },
];

export default xlsxRoutes;
