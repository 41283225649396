import { lazy } from "react";

const Purchaserequisitionview = lazy(() => import("./Purchaserequisitionview"));

const purchaserequisitionviewRoutes = [
  {
    path: "/purchaserequisitionview",
    component: Purchaserequisitionview,
  },
];

export default purchaserequisitionviewRoutes;
