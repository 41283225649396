import { lazy } from 'react';
const fileAttUpload = lazy(() => import('./fileAttUpload'));

const fileAttUploadRoutes = [
  {
    path: '/fileattupload',
    component: fileAttUpload
  }
];

export default fileAttUploadRoutes;