import { lazy } from "react";

const Dispatchnote = lazy(() => import("./Dispatchnote"));

const dispatchnoteRoutes = [
  {
    path: "/dispatchnote",
    component: Dispatchnote,
  },
];

export default dispatchnoteRoutes;
