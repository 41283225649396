import { lazy } from 'react';
const DashboardEng = lazy(() => import('./dashboardEng'));

const dashboardEngRoutes = [
  {
    path: '/dashboardeng',
    component: DashboardEng
  }
];

export default dashboardEngRoutes;
