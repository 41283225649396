import { lazy } from "react";

const LabList = lazy(() => import("./LabList"));

const labRoutes = [
  {
    path: "/lab/list",
    component: LabList,
  },
];
export default labRoutes;
