import { lazy } from 'react';
const orderPreApproval = lazy(() => import('./orderPreApproval'));

const orderPreApprovalRoutes = [
  {
    path: '/orderPreApproval',
    component: orderPreApproval
  }
];

export default orderPreApprovalRoutes;