import { lazy } from "react";
const SampleQr = lazy(() => import("./sampleQr"));

const sampleQrRoutes = [
    {
        path: "/sampleqr",
        component: SampleQr,
    },
];

export default sampleQrRoutes;