import { lazy } from "react";

const AdvancepaymentPay = lazy(() => import("./AdvancepaymentPay"));

const advancepaymentPayRoutes = [
  {
    path: "/advancepaymentpay/:batchId",
    component: AdvancepaymentPay,
  },
];

export default advancepaymentPayRoutes;
