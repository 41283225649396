import { lazy } from "react";

const Workflow = lazy(() => import("./Workflow"));

const workflowRoutes = [
  {
    path: "/workflow/list",
    component: Workflow,
  },
];

export default workflowRoutes;
