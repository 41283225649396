import { lazy } from "react";

const Dryer = lazy(() => import("./Dryer"));

const dryerRoutes = [
  {
    path: "/dryer",
    component: Dryer,
  },
];

export default dryerRoutes;
