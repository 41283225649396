import { lazy } from "react";

const Pettycashpaymnet = lazy(() => import("./Pettycashpaymnet"));

const pettycashpaymnetRoutes = [
  {
    path: "/pettycashpaymnet/list",
    component: Pettycashpaymnet,
  },
];

export default pettycashpaymnetRoutes;
