import { lazy } from "react";

const Debitnote = lazy(() => import("./Debitnote"));

const debitnoteRoutes = [
  {
    path: "/debitnote/:supId",
    component: Debitnote,
  },
];

export default debitnoteRoutes;
