import { lazy } from "react";

const Vpdinv = lazy(() => import("./Vpdinv"));

const vpdinvRoutes = [
  {
    path: "/vpdinv",
    component: Vpdinv,
  },
];

export default vpdinvRoutes;
