import { lazy } from "react";

const Viewsupplier = lazy(() => import("./Viewsupplier"));

const viewSupplierRoutes = [
  {
    path: "/viewsupplier",
    component: Viewsupplier,
  },
];

export default viewSupplierRoutes;
