import { lazy } from "react";

const Receiptreverse = lazy(() => import("./Receiptreverse"));

const receiptreverseRoutes = [
  {
    path: "/receiptreverse",
    component: Receiptreverse,
  },
];

export default receiptreverseRoutes;
