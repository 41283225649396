import { lazy } from 'react';
const ltxmatissue = lazy(() => import('./ltxmatissue'));

const ltxmatissueRoutes = [
  {
    path: '/ltxmatissue',
    component: ltxmatissue
  }
];

export default ltxmatissueRoutes;