import { lazy } from 'react';
const engchecklist = lazy(() => import('./engchecklist'));

const engchecklistRoutes = [
  {
    path: '/engchecklist',
    component: engchecklist
  }
];

export default engchecklistRoutes;