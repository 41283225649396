import { lazy } from "react";

const Workflowinfo = lazy(() => import("./Workflowinfo"));

const workflowInfoRoutes = [
  {
    path: "/workflowinfo",
    component: Workflowinfo,
  },
];

export default workflowInfoRoutes;
