import { lazy } from "react";

const Approversupplier = lazy(() => import("./Approversupplier"));

const approversupplierRoutes = [
  {
    path: "/approversupplier",
    component: Approversupplier,
  },
];

export default approversupplierRoutes;
