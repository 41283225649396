import { lazy } from "react";

const Chqprint = lazy(() => import("./Chqprint"));
const ChqprintNew = lazy(() => import("./ChqprintNew"));

const chqprintRoutes = [
  {
    path: "/chqprint",
    component: ChqprintNew,
  },
];

export default chqprintRoutes;
