import { lazy } from "react";

const OrderCompletion = lazy(() => import("./OrderCompletion"));

const prodPlanningRoutes = [
  {
    path: "/ordercompletion",
    component: OrderCompletion,
  },
];

export default prodPlanningRoutes;
