import { lazy } from "react";

const Phead = lazy(() => import("./Phead"));

const pheadRoutes = [
  {
    path: "/phead",
    component: Phead,
  },
];

export default pheadRoutes;
