import { lazy } from "react";

const Reports = lazy(() => import("./Reports"));

const reportsRoutes = [
  {
    path: "/reports",
    component: Reports,
  },
];

export default reportsRoutes;
