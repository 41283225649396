import { lazy } from "react";

const Bttankdrc = lazy(() => import("./Bttankdrc"));

const bttankdrcRoutes = [
  {
    path: "/bttankdrc",
    component: Bttankdrc,
  },
];

export default bttankdrcRoutes;
