import { lazy } from "react";

const Debtorsageanalysis = lazy(() => import("./Debtorsageanalysis"));

const debtorsageanalysisRoutes = [
  {
    path: "/debtorsageanalysis",
    component: Debtorsageanalysis,
  },
];

export default debtorsageanalysisRoutes;
