import { lazy } from 'react';
const attendance = lazy(() => import('./timeAttendance'));

const timeAttendanceRoutes = [
  {
    path: '/timeAttendance',
    component: attendance
  }
];

export default timeAttendanceRoutes;