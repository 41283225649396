import { lazy } from "react";

const Recut = lazy(() => import("./Recut"));

const recutRoutes = [
  {
    path: "/recut",
    component: Recut,
  },
];

export default recutRoutes;
