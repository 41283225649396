import { lazy } from "react";

const Revaluation = lazy(() => import("./Revaluation"));

const revaluationRoutes = [
  {
    path: "/revaluation",
    component: Revaluation,
  },
];

export default revaluationRoutes;
