import { lazy } from 'react';
const downTime = lazy(() => import('./downtime'));

const downTimeRoutes = [
  {
    path: '/downtime',
    component: downTime
  }
];

export default downTimeRoutes;