import { lazy } from 'react';
const dashboardFinan = lazy(() => import('./dashboardFinan'));

const dashboardFinanRoutes = [
  {
    path: '/dashboardFinan',
    component: dashboardFinan
  }
];

export default dashboardFinanRoutes;
