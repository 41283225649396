import { lazy } from "react";

const CreditInvoice = lazy(() => import("./CreditInvoice"));

const creditInvoiceRoutes = [
  {
    path: "/creditinvoice/:doccod",
    component: CreditInvoice,
  },
];

export default creditInvoiceRoutes;
