import { lazy } from 'react';
const prodPlan = lazy(() => import('./prodPlan'));

const prodPlanRoutes = [
  {
    path: '/prodplan',
    component: prodPlan
  }
];

export default prodPlanRoutes;
