import { lazy } from 'react';
const shiftalloaction = lazy(() => import('./shiftallocation'));

const shiftRoutes = [
  {
    path: '/shiftallocation',
    component: shiftalloaction
  }
];

export default shiftRoutes;