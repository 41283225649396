import { lazy } from "react";

const Grn = lazy(() => import("./Grn"));

const grnRoutes = [
  {
    path: "/grn",
    component: Grn,
  },
];

export default grnRoutes;
