import { lazy } from 'react';
const orderProfitability = lazy(() => import('./orderProfitability'));

const orderProfitabilityRoutes = [
  {
    path: '/orderprofitability',
    component: orderProfitability
  }
];

export default orderProfitabilityRoutes;
