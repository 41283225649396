import { lazy } from "react";

const Btvfadrcupd = lazy(() => import("./Btvfadrcupd"));

const btvfadrcupdRoutes = [
  {
    path: "/btvfadrcupd",
    component: Btvfadrcupd,
  },
];

export default btvfadrcupdRoutes;
