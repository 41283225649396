import { lazy } from "react";

const Organicallocation = lazy(() => import("./Organicallocation"));

const organicallocationRoutes = [
  {
    path: "/organicallocation",
    component: Organicallocation,
  },
];

export default organicallocationRoutes;
