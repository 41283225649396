import { lazy } from "react";

const PendingSales = lazy(() => import("./PendingSales"));

const pendingSalesRoutes = [
  {
    path: "/pendingsales",
    component: PendingSales,
  },
];

export default pendingSalesRoutes;
