import { lazy } from "react";

const Reprint = lazy(() => import("./Reprint"));

const reprintRoutes = [
  {
    path: "/reprint",
    component: Reprint,
  },
];

export default reprintRoutes;
