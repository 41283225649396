import { lazy } from "react";

const Fgbarcode = lazy(() => import("./Fgbarcode"));

const reprintnewRoutes = [
  {
    path: "/fgbarcode2",
    component: Fgbarcode,
  },
];

export default reprintnewRoutes;
