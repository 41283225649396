import { lazy } from "react";

const Labvfa = lazy(() => import("./Labvfa"));

const labvfaRoutes = [
  {
    path: "/labvfa",
    component: Labvfa,
  },
];

export default labvfaRoutes;
