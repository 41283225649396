import { lazy } from 'react';
const fgsum = lazy(() => import('./fgsum'));

const fgsumRoutes = [
  {
    path: '/fgsum',
    component: fgsum
  }
];

export default fgsumRoutes;
