import { lazy } from 'react';
const priceinquiryUpdate = lazy(() => import('./priceinquiryUpdate'));

const priceinquiryUpdateRoutes = [
  {
    path: '/priceinquiryupdate',
    component: priceinquiryUpdate
  }
];

export default priceinquiryUpdateRoutes;
