import { lazy } from "react";

const Pettycashre = lazy(() => import("./Pettycashre"));

const pettycashreRoutes = [
  {
    path: "/pettycashre",
    component: Pettycashre,
  },
];

export default pettycashreRoutes;
