import { lazy } from "react";

const Pricemodule = lazy(() => import("./Pricemodule"));

const pricemoduleRoutes = [
  {
    path: "/pricemodule",
    component: Pricemodule,
  },
];

export default pricemoduleRoutes;
