import { lazy } from "react";

const Bttobt = lazy(() => import("./Bttobt"));

const bttobtRoutes = [
  {
    path: "/bttobt",
    component: Bttobt,
  },
];

export default bttobtRoutes;
