import { lazy } from "react";

const Finishedgoodin = lazy(() => import("./Finishedgoodin"));

const finishedgoodinRoutes = [
  {
    path: "/finishedgoodin",
    component: Finishedgoodin,
  },
];

export default finishedgoodinRoutes;
