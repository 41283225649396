import { lazy } from "react";

const SelectCompany = lazy(() => import("./SelectCompany"));


const selectCompanyRoutes = [
  {
    path: "/select-company",
    component: SelectCompany
  },
  
  
];

export default selectCompanyRoutes;