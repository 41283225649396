import { lazy } from "react";

const Avupload = lazy(() => import("./Avupload"));

const avuploadRoutes = [
  {
    path: "/avupload",
    component: Avupload,
  },
];

export default avuploadRoutes;
