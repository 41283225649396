import { lazy } from "react";

const Barcode = lazy(() => import("./Barcode"));

const barcodeRoutes = [
  {
    path: "/barcode",
    component: Barcode,
  },
];

export default barcodeRoutes;
