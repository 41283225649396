import { lazy } from "react";

const OrderCompletionnewseq = lazy(() => import("./OrderCompletionnewseq"));

const orderCompletionnewseqRoutesNew = [
  {
    path: "/OrderCompletionnewseq",
    component: OrderCompletionnewseq,
  },
];

export default orderCompletionnewseqRoutesNew;
