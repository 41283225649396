import { lazy } from "react";

const RouteList = lazy(() => import("./RouteList"));

const routeRoutes = [
  {
    path: "/route/list",
    component: RouteList,
  },
];

export default routeRoutes;
