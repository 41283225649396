import { lazy } from 'react';

const batchAmendment = lazy(() => import('./batchAmendment'));

const batchAmendmentRoutes = [
  {
    path: '/batchamendment',
    component: batchAmendment
  }
];

export default batchAmendmentRoutes;
