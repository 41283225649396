import { lazy } from "react";

const Fgstock = lazy(() => import("./Fgstock"));

const fgstockRoutes = [
  {
    path: "/fgstock",
    component: Fgstock,
  },
];

export default fgstockRoutes;
