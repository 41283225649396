import { lazy } from "react";

const Fabricationwastage = lazy(() => import("./Fabricationwastage"));

const fabricationwastageRoutes = [
  {
    path: "/fabricationwastage",
    component: Fabricationwastage,
  },
];

export default fabricationwastageRoutes;
