import { lazy } from "react";
const dummyTransfer = lazy(() => import("./dummyTransfer"));

const dummyTransferRoutes = [
    {
        path: '/dummytransfer',
        component: dummyTransfer
    }
];

export default dummyTransferRoutes;