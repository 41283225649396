import { lazy } from "react";

const PettyCashListening = lazy(() => import("./PettyCashListening"));

const pettyCashListeningRoutes = [
  {
    path: "/pettycashlistening",
    component: PettyCashListening,
  },
];

export default pettyCashListeningRoutes;
