import { lazy } from "react";

const Iou = lazy(() => import("./Iou"));

const iouRoutes = [
  {
    path: "/iou",
    component: Iou,
  },
];

export default iouRoutes;
