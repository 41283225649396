import { lazy } from "react";

const PicItemList = lazy(() => import("./PicItemList"));

const picitemRoutes = [
  {
    path: "/picitem/list",
    component: PicItemList,
  },
];

export default picitemRoutes;
