import { lazy } from "react";

const Advancepayment = lazy(() => import("./Advancepayment"));

const advancepaymentRoutes = [
  {
    path: "/advancepayment",
    component: Advancepayment,
  },
];

export default advancepaymentRoutes;
