import { lazy } from "react";

const LoadingSheet = lazy(() => import("./LoadingSheet"));

const loadingSheetRoutes = [
  {
    path: "/loadingsheet",
    component: LoadingSheet,
  },
];

export default loadingSheetRoutes;
