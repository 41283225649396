import { lazy } from "react";

const Mvdr = lazy(() => import("./Mvdr"));

const mvdrRoutes = [
  {
    path: "/mvdr",
    component: Mvdr,
  },
];

export default mvdrRoutes;
