import { lazy } from 'react';
const loangiven = lazy(() => import('./loangiven'));

const loangivenRoutes = [
  {
    path: '/loangiven',
    component: loangiven
  }
];

export default loangivenRoutes;