import { lazy } from "react";

const Approvelatex = lazy(() => import("./Approvelatex"));

const approvelatexRoutes = [
  {
    path: "/approvelatex",
    component: Approvelatex,
  },
];

export default approvelatexRoutes;
