import { lazy } from "react";

const Whatsapp = lazy(() => import("./Whatsapp"));

const whatsappRoutes = [
  {
    path: "/whatsapp",
    component: Whatsapp,
  },
];

export default whatsappRoutes;
