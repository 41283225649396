import { lazy } from "react";

const Supplierwise = lazy(() => import("./Supplierwise"));

const supplierwiseRoutes = [
  {
    path: "/supplierwise",
    component: Supplierwise,
  },
];

export default supplierwiseRoutes;
