import { lazy } from 'react';
const loanborrowed = lazy(() => import('./loanborrowed'));

const loanborrowedRoutes = [
  {
    path: '/loanborrowed',
    component: loanborrowed
  }
];

export default loanborrowedRoutes;