import { lazy } from "react";

const Prtoponew = lazy(() => import("./Prtoponew"));

const prtoponewRoutes = [
  {
    path: "/prtoponew",
    component: Prtoponew,
  },
];

export default prtoponewRoutes;
