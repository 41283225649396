import { lazy } from "react";

const Purchaserequisition = lazy(() => import("./Purchaserequisition"));

const purchaserequisitionRoutes = [
  {
    path: "/purchaserequisition",
    component: Purchaserequisition,
  },
];

export default purchaserequisitionRoutes;
