import { lazy } from "react";

const Fgstockorder = lazy(() => import("./Fgstockorder"));

const fgstockorderRoutes = [
  {
    path: "/fgstockorder",
    component: Fgstockorder,
  },
];

export default fgstockorderRoutes;
