import { lazy } from "react";

const Poing = lazy(() => import("./Poing"));

const poingRoutes = [
  {
    path: "/poing",
    component: Poing,
  },
];

export default poingRoutes;
