import { lazy } from 'react';

const orderApproval = lazy(() => import('./orderApproval'));

const orderApprovalRoutes = [
  {
    path: '/orderapproval',
    component: orderApproval
  }
];

export default orderApprovalRoutes;