import { lazy } from 'react';
const BankBalance = lazy(() => import('./BankBalance'));

const BankBalanceRoutes = [
  {
    path: '/bankbalance',
    component: BankBalance
  }
];

export default BankBalanceRoutes;
