import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Role = lazy(() => import("./Role"));

const roleRoutes = [
  {
    path: "/role/list",
    component: Role,
  },
];

export default roleRoutes;
