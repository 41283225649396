import { lazy } from "react";

const Hrview = lazy(() => import("./Hrview"));

const hrviewRoutes = [
  {
    path: "/hrview",
    component: Hrview,
  },
];

export default hrviewRoutes;
