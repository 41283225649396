import { lazy } from "react";

const BankbBlances = lazy(() => import("./BankbBlances"));

const waterbillRoutes = [
  {
    path: "/bankbblances",
    component: BankbBlances,
  },
];

export default waterbillRoutes;
