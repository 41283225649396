import { lazy } from "react";

const Orderflowrla = lazy(() => import("./Orderflowrla"));

const orderflowrlaRoutes = [
  {
    path: "/orderflowrla",
    component: Orderflowrla,
  },
];

export default orderflowrlaRoutes;
