import { lazy } from "react";

const BatchCreation = lazy(() => import("./BatchCreation"));

const batchCreationRoutes = [
  {
    path: "/batchcreation/list",
    component: BatchCreation,
  },
];

export default batchCreationRoutes;
