import { lazy } from "react";

const ResetPassword = lazy(() => import("./ResetPassword"));

const resetpasswordUserRoutes = [
  {
    path: "/resetpassword",
    component: ResetPassword,
  },
];

export default resetpasswordUserRoutes;
