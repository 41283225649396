import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Form = lazy(() => import("./Form"));

const formRoutes = [
  {
    path: "/form/list",
    component: Form,
  },
];

export default formRoutes;
