import { lazy } from "react";

const Salesorder = lazy(() => import("./Salesorder"));

const salesorderRoutes = [
  {
    path: "/salesorder",
    component: Salesorder,
  },
];

export default salesorderRoutes;
