import { lazy } from "react";

const Verticalcutter = lazy(() => import("./Verticalcutter"));

const verticalcutterRoutes = [
  {
    path: "/verticalcutter",
    component: Verticalcutter,
  },
];

export default verticalcutterRoutes;
