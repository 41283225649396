import { lazy } from "react";

const Uploadtype = lazy(() => import("./Uploadtype"));

const uploadtypeRoutes = [
  {
    path: "/uploadtype/list",
    component: Uploadtype,
  },
];

export default uploadtypeRoutes;
