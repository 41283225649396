import { lazy } from "react";

const AssignLatexRoutesList = lazy(() => import("./AssignLatexRoutesList"));

const assignLatexRoutes = [
  {
    path: "/latex/list",
    component: AssignLatexRoutesList,
  },
];
export default assignLatexRoutes;
