import { lazy } from "react";

const OrderCompletionV2 = lazy(() => import("./OrderCompletionV2"));

const ordercompletionV2Routes = [
  {
    path: "/ordercompletionv2",
    component: OrderCompletionV2,
  },
];

export default ordercompletionV2Routes;
