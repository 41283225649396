import { lazy } from "react";

const Hr = lazy(() => import("./Hr"));

const hrRoutes = [
  {
    path: "/hr",
    component: Hr,
  },
];

export default hrRoutes;
