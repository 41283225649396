import { lazy } from "react";

const Home = lazy(() => import("./Home"));

const homeRoutes = [
  {
    path: "/home",
    component: Home,
  },
];

export default homeRoutes;
