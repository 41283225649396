import { lazy } from "react";

const Sample = lazy(() => import("./Sample"));

const sampleRoutes = [
  {
    path: "/sample",
    component: Sample,
  },
];

export default sampleRoutes;
