import { lazy } from "react";

const ConfirmProduction = lazy(() => import("./ConfirmProduction"));

const confirmProductionRoutes = [
  {
    path: "/confirmproduction",
    component: ConfirmProduction,
  },
];

export default confirmProductionRoutes;
