import { lazy } from "react";

const Waterbill = lazy(() => import("./Waterbill"));

const waterbillRoutes = [
  {
    path: "/waterbill",
    component: Waterbill,
  },
];

export default waterbillRoutes;
