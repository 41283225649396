import { lazy } from "react";

const Report = lazy(() => import("./Report"));

const reportRoutes = [
  {
    path: "/report/:id",
    component: Report,
  },
];

export default reportRoutes;
