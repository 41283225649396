import { lazy } from "react";

const Reprintnew = lazy(() => import("./Reprintnew"));

const reprintnewRoutes = [
  {
    path: "/fgbarcode",
    component: Reprintnew,
  },
];

export default reprintnewRoutes;
