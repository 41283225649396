import { lazy } from "react";

const OrderCompletionNew = lazy(() => import("./OrderCompletionNew"));

const orderCompletionRoutesNew = [
  {
    path: "/ordercompletionnew",
    component: OrderCompletionNew,
  },
];

export default orderCompletionRoutesNew;
