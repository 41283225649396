import { lazy } from "react";

const CompanyList = lazy(() => import("./CompanyList"));

const companyRoutes = [
  {
    path: "/company/list",
    component: CompanyList,
  },
];

export default companyRoutes;
