import { lazy } from "react";

const SupplierList = lazy(() => import("./SupplierList"));

const supplierRoutes = [
  {
    path: "/supplier/list/:type?",
    component: SupplierList,
  },
];

export default supplierRoutes;
