import { lazy } from 'react';

const TimeSheet = lazy(() => import('./TimeSheet'));

const TimeSheetRoutes = [
  {
    path: '/timesheet',
    component: TimeSheet
  }
];

export default TimeSheetRoutes;
