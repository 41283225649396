import { lazy } from "react";

const Fabricationfinal = lazy(() => import("./Fabricationfinal"));

const fabricationfinalRoutes = [
  {
    path: "/fabricationfinal",
    component: Fabricationfinal,
  },
];

export default fabricationfinalRoutes;
