import { lazy } from "react";

const Dieselupdate = lazy(() => import("./Dieselupdate"));

const dieselupdateRoutes = [
  {
    path: "/dieselupdate",
    component: Dieselupdate,
  },
];

export default dieselupdateRoutes;
