import { lazy } from "react";

const LocationList = lazy(() => import("./LocationList"));

const locationRoutes = [
  {
    path: "/location/list",
    component: LocationList,
  },
];

export default locationRoutes;
